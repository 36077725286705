import { configureStore } from '@reduxjs/toolkit';

import accountsReducer from '../pages/accounts/reducerSlices/accountsSlice';
import leadsReducer from '../pages/lead/reducerSlices/leadSlice'; 
import locationsReducer from '../pages/locations/reducerSlices/locationSlice';
import scheduleReducer from '../pages/appointment/reducerSlices/scheduleSlice';



export default configureStore({
    reducer: {
        accounts: accountsReducer,
        leads: leadsReducer,
        locations: locationsReducer,
        schedules:scheduleReducer,
    },
});