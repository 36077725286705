import React, { useEffect, useState } from "react";
import { IonCol, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";
import SaveButton from "../../../components/buttons/saveButton";
import TopNavBar from "../../../components/navbars/topNavBar";
import { createAppointment, selectAppointmentById, selectCreationAppointmentStatus, selectRecentClickedAppointment, selectRecentClickedAppointmentData, updateSingleAppointmentData } from "../../lead/reducerSlices/leadSlice";
import { useDispatch, useSelector } from "react-redux";
import { _saveAppointmentEndTime, onValueChanged, sections } from "../services/appointmentSaver";
import ShortText from "../../../components/controls/textboxes/shortText";
import Selector from "../../../components/controls/selectors/selector";
import { fetchStatus, tenantId } from "../../../app/api/client";
import { getAllShedules, selectCalanderSelectedDate } from "../reducerSlices/scheduleSlice";
import Statement from "../../../components/controls/textboxes/statement";
import EllipsisLoader from "../../../components/loaders/ellipsisLoader";
import { isFormValid } from "../../../components/controls/services/formService";
import DateTime from "../../../components/controls/textboxes/datetime";
import { toLocalDate } from "../../../app/common/common";


function AppointmentDetails() {

    const history = useHistory();
    const dispatch = useDispatch();

    const APPOINTMENT_FORM = "Appointmentform";

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);

    const [afSubmitted, setAFSubmitted] = useState(false);
    const [aFInvalid, setAFInvalid] = useState(false);

    const appointment = useSelector(selectAppointmentById);
    const recentClickedAppointment = useSelector(selectRecentClickedAppointment);
    const recentClickedAppointmentData = useSelector(selectRecentClickedAppointmentData);

    const creationAppointmentStatus = useSelector(selectCreationAppointmentStatus);

    const [showSubmitToast, setShowSubmitToast] = useState(false);


    useEffect(() => {
        if (recentClickedAppointment === undefined) {
            history.push('lead');
        }

        if (recentClickedAppointment === 0) {
            setCreatemode(true);

            // Default Appointment Obj Update
            let _appointmentObj = Object.assign({}, appointment);
            _appointmentObj.leadId = recentClickedAppointmentData.leadId;
            _appointmentObj.leadName = recentClickedAppointmentData.leadName;
            _appointmentObj.locationId = recentClickedAppointmentData.locationId;

            let _newAppointmentObj = _appointmentObj;
            dispatch(updateSingleAppointmentData({
                appointment: _newAppointmentObj,
            }));
            // Default Appointment Obj Update

        } else {
            setCreatemode(false);
        }

    }, [recentClickedAppointment, recentClickedAppointmentData.leadId]);

    const onBackButtonClicked = (e) => {
        setAFSubmitted(false);
        if (createMode) {
            history.push('leaddetails');
        } else {
            history.push('appointment');
        }
    };

    const onValueUpdated = (e, section, value) => {
        let _appointmentObj = Object.assign({}, appointment);
        let _newAppointmentObj = onValueChanged(_appointmentObj, e, section, value);

        if (recentClickedAppointment === 0 && e.target.name === "txtStartTime") {
            _newAppointmentObj = _saveAppointmentEndTime(_newAppointmentObj);
        }

        dispatch(updateSingleAppointmentData({
            appointment: _newAppointmentObj,
            // operation: Operation.UPDATED
        }));
    };

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (isFormValid(APPOINTMENT_FORM)) {
            if (createMode) {
                if (appointment.leadId !== "") {
                    dispatch(createAppointment({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEAPPOINTMENT",
                        payload: {
                            id: "",
                            data: JSON.stringify({
                                appointment: JSON.stringify(appointment)
                            })
                        }
                    }));
                }
            }
            else {
                const appointmentId = recentClickedAppointment;
                const _appointment = { ...appointment }
                delete _appointment.locationId;
                if (appointmentId != "" && appointmentId != 0) {
                    dispatch(createAppointment({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEAPPOINTMENT",
                        payload: {
                            id: appointmentId,
                            data: JSON.stringify({
                                appointment: JSON.stringify(_appointment)
                            })
                        }
                    }));
                } else {
                    console.log('error something went wrong appointment');
                }

            }
            setAFSubmitted(false);
        } else {
            setAFInvalid(true);
        }
        setAFSubmitted(true);
    };

    useEffect(() => {
        if (creationAppointmentStatus === fetchStatus.SUCCEEDED) {
            setShowSubmitToast(true);
            // alert("Saved successfully");
            _loadSchedules();
            setAFSubmitted(false);
            if (createMode) {
                history.push('leaddetails');
            } else {
                history.push('appointment');
            }
        }
    }, [creationAppointmentStatus]);


    const calanderSelectedDate = useSelector(selectCalanderSelectedDate);

    const _loadSchedules = async () => {

        let whereClause = { "location": { "value": [], "text": "All" }, "scheduleStatus": { "value": [], "text": "All" }, "name": "" };

        await dispatch(getAllShedules({
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETAPPOINTMENTSFORMOBILEAPP",
                payload: {
                    id: "",
                    data: JSON.stringify({
                        where: whereClause,
                        date: calanderSelectedDate
                    })
                }
            }
        }));
    }

    const onToastDismissed = () => {
        setShowSubmitToast(false);
    };

    return (
        <IonPage>
            <TopNavBar
                pageTitle="Appointment Details"
                onBackButtonClicked={(e) => onBackButtonClicked(e)} />
            <IonContent>
                <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol size="11">
                        <IonLabel className="c-heading">Appointment</IonLabel>
                    </IonCol>
                </IonRow>

                {appointment && appointment.calendarData &&
                    <>
                        <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonLabel position="stacked">Subject</IonLabel>
                                    <ShortText
                                        id="txtSubject"
                                        name="txtSubject"
                                        ShortTextClasses="form-control"
                                        placeholder="Subject"
                                        value={appointment && appointment.calendarData ? appointment.calendarData.Subject : ""}
                                        onChange={(e) => onValueUpdated(e, sections.CALENDARDATA_DETAILS, null)}
                                        required={true}
                                        errorMessage={"Enter subject"}
                                        form={APPOINTMENT_FORM}
                                        submited={afSubmitted} />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="auto">
                                <IonList>
                                    <IonItem lines="none">
                                        <IonLabel position="stacked">Status</IonLabel>
                                        <Selector
                                            id="drpStatus"
                                            name="drpStatus"
                                            SelectorClasses="form-control"
                                            value={appointment && appointment.calendarData ? appointment.calendarData.Status : ""}
                                            required={true}
                                            errorMessage={"Select status"}
                                            form={APPOINTMENT_FORM}
                                            submited={afSubmitted}
                                            options={
                                                <>
                                                    <IonSelectOption value="Open">Open</IonSelectOption>
                                                    <IonSelectOption value="Done" disabled={createMode && true}>Done</IonSelectOption>
                                                    <IonSelectOption value="Rejected" disabled={createMode && true}>Rejected</IonSelectOption>
                                                </>
                                            } onChange={(e) => onValueUpdated(e, sections.CALENDARDATA_DETAILS, null)}
                                            placeholder="-- Select Status --"
                                        />
                                    </IonItem>
                                </IonList>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonLabel>Start Time</IonLabel>
                                    <DateTime
                                        id="txtStartTime"
                                        name="txtStartTime"
                                        DateTimeClasses="form-control"
                                        placeholder="Time"
                                        value={appointment && appointment.calendarData && appointment.calendarData.StartTime ? appointment.calendarData.StartTime : ""}
                                        // value={ "2023-06-21T12:30:00+05:30"}
                                        onChange={e => onValueUpdated(e, sections.CALENDARDATA_DETAILS, e.detail.value)}
                                        required={true}
                                        errorMessage={"Select start time"}
                                        form={APPOINTMENT_FORM}
                                        submited={afSubmitted}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonLabel>To Time</IonLabel>
                                    <DateTime
                                        id="txtEndTime"
                                        name="txtEndTime"
                                        DateTimeClasses="form-control"
                                        placeholder="Time"
                                        value={appointment && appointment.calendarData && appointment.calendarData.EndTime ? appointment.calendarData.EndTime : ""}
                                        onChange={e => onValueUpdated(e, sections.CALENDARDATA_DETAILS, e.detail.value)}
                                        required={true}
                                        errorMessage={"Select to time"}
                                        form={APPOINTMENT_FORM}
                                        submited={afSubmitted}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        {/* <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonLabel>Start Time</IonLabel>
                                    <IonDatetimeButton datetime="txtStartTime"></IonDatetimeButton>
                                    <IonModal keepContentsMounted={true}>
                                        <IonDatetime id="txtStartTime"
                                            presentation="date-time"
                                            preferWheel={true}
                                            name="txtStartTime"
                                            value={appointment && appointment.calendarData && appointment.calendarData.StartTime ? appointment.calendarData.StartTime : ""}
                                            // onIonChange={(e) => { console.log(e.target.value); console.log(e) }}
                                            onIonChange={e => onValueUpdated(e, sections.CALENDARDATA_DETAILS, e.detail.value)}
                                        ></IonDatetime>
                                    </IonModal>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonLabel>To Time</IonLabel>
                                    <IonDatetimeButton datetime="txtEndTime"></IonDatetimeButton>
                                    <IonModal keepContentsMounted={true}>
                                        <IonDatetime id="txtEndTime"
                                            presentation="date-time"
                                            preferWheel={true}
                                            name="txtEndTime"
                                            value={appointment && appointment.calendarData && appointment.calendarData.EndTime ? appointment.calendarData.EndTime : ""}
                                            // onIonChange={(e) => { console.log(e.target.value); console.log(e) }}
                                            onIonChange={e => onValueUpdated(e, sections.CALENDARDATA_DETAILS, e.detail.value)}
                                        ></IonDatetime>
                                    </IonModal>
                                </IonItem>
                            </IonCol>
                        </IonRow> */}


                        <IonRow>
                            <IonCol>
                                <IonList>
                                    <IonItem lines="none">
                                        <IonLabel position="stacked">Description</IonLabel>
                                        <Statement
                                            id="txtDescription"
                                            name="txtDescription"
                                            value={appointment && appointment.calendarData ? appointment.calendarData.Description : ""}
                                            rows={3}
                                            StatementClasses="form-control"
                                            onChange={(e) => onValueUpdated(e, sections.CALENDARDATA_DETAILS, null)}
                                            // required={jobForm && jobForm.Notes.Required}
                                            // errorMessage={jobForm && jobForm.Notes.Validation}
                                            form={APPOINTMENT_FORM}
                                            submited={afSubmitted}
                                            placeholder="Enter note..." />
                                    </IonItem>
                                </IonList>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonLabel position="stacked">Meeting With</IonLabel>
                                    <ShortText
                                        id="txtMeetingWith"
                                        name="txtMeetingWith"
                                        ShortTextClasses="form-control"
                                        placeholder="Meeting with"
                                        value={appointment && appointment.calendarData ? appointment.calendarData.MeetingWith : ""}
                                        onChange={(e) => onValueUpdated(e, sections.CALENDARDATA_DETAILS, null)}
                                        // required={}
                                        // errorMessage={}
                                        form={APPOINTMENT_FORM}
                                        submited={afSubmitted} />
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        {!createMode &&
                            <IonRow>
                                <IonCol size="7">
                                    <IonItem lines="none">
                                        <IonLabel position="stacked">Feedback</IonLabel>
                                        <Selector
                                            id="drpFeedback"
                                            name="drpFeedback"
                                            SelectorClasses="form-control"
                                            value={appointment && appointment.calendarData ? appointment.calendarData.Feedback : ""}
                                            // required={}
                                            // errorMessage={}
                                            form={APPOINTMENT_FORM}
                                            submited={afSubmitted}
                                            options={
                                                <>
                                                    <IonSelectOption value="Positive">Positive</IonSelectOption>
                                                    <IonSelectOption value="Negative">Negative</IonSelectOption>
                                                    <IonSelectOption value="Rescheduled">Rescheduled</IonSelectOption>
                                                </>
                                            } onChange={(e) => onValueUpdated(e, sections.CALENDARDATA_DETAILS, null)}
                                            placeholder="-- Select Feedback --"
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        }

                        <IonRow className="ion-justify-content-center">
                            {creationAppointmentStatus !== fetchStatus.LOADING ?
                                <IonCol size="11">
                                    <SaveButton onSaveButtonClicked={(e) => onSaveClicked(e, "", createMode)} />
                                </IonCol>
                                : <IonCol size="auto">
                                    <EllipsisLoader />
                                </IonCol>
                            }
                        </IonRow>

                    </>
                }

                <IonToast
                    position="top"
                    header="Submitted"
                    color="success"
                    isOpen={showSubmitToast}
                    onDidDismiss={() => onToastDismissed()}
                    message="Saved successfully"
                    duration={2000}
                />

            </IonContent>
        </IonPage>
    )
}

export default AppointmentDetails;