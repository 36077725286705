import React, { useEffect } from "react";
import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";

import { callOutline, navigate, calendarClear, timeOutline, closeCircleOutline, checkmarkDone, business } from 'ionicons/icons';
import TopNavBar from "../../../components/navbars/topNavBar";
import { createNewAppointment, updateRecentClickedAppointment, updateSingleAppointmentData } from "../../lead/reducerSlices/leadSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllShedules, getRouteSchedules, selectAllSchedules, selectRouteSchedules, selectRouteSchedulesStatus, selectStatus, updateCalanderDate } from "../reducerSlices/scheduleSlice";
import { fetchStatus, tenantId } from "../../../app/api/client";
import { accountService } from "../../accounts/services/accountService";
import WeekCalendar from "../../../components/calendar/weekCalendar";
import { _getUtcTimestamp, toLocalDate, toLocalDateOnly, toLocalTimeOnly } from "../../../app/common/common";
import Moment from 'moment';

import mapImg from "./../../../assets/map-icon.png";
import BootomNavBar from "../../../components/navbars/bootomNavBar";

import GMap from '../../../components/map/GMap';
import GMapWaypointsDirections from "../../../components/map/GMapWaypointsDirections";
import GridLoader from "../../../components/loaders/gridLoader";

const GOOGLE_MAP_API_KEY = 'AIzaSyAakzSaZd7t-EBQkMQOu5U3QzhHV4yr1CY';
// load google map script
const loadGoogleMapScript = (callback) => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
        callback();
    } else {
        // const googleMapScript = document.createElement("script");
        // googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
        // window.document.body.appendChild(googleMapScript);
        // googleMapScript.addEventListener("load", callback);
    }
}

function Appointment() {

    const history = useHistory();
    const dispatch = useDispatch();

    const [activeMenu, setActiveMenu] = useState("list");

    const user = accountService.getAuthenticatedUser();

    //Calander Start    
    const [showDetails, setShowDetails] = useState(false);
    const [data, setData] = useState(toLocalDateOnly(new Date()));

    const showDetailsHandle = (dayStr) => {
        setData(dayStr);
        setShowDetails(true);
    };
    //Calander End

    const [appointmentsList, setAppointmentsList] = useState();

    const schedules = useSelector(selectAllSchedules);
    const schedulesRequestStatus = useSelector(selectStatus);

    useEffect(() => {
        if (schedules) {
            let _schedules = schedules.map(d => ({ ...d, "utcFormatStartTime": _getUtcTimestamp(d.calendarData.StartTime) }));
            _schedules.sort((a, b) => {
                return a.utcFormatStartTime - b.utcFormatStartTime;
            });
            setAppointmentsList(_schedules);
        }
    }, [data, schedules]);

    useEffect(() => {
        if (data) {
            dispatch(updateCalanderDate({
                date: data,
            }));
        }
    }, [data]);

    let whereClause = { "location": { "value": [], "text": "All" }, "scheduleStatus": { "value": [], "text": "All" }, "name": "" };

    const _loadSchedules = async (whereClause) => {
        await dispatch(getAllShedules({
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETAPPOINTMENTSFORMOBILEAPP",
                payload: {
                    id: "",
                    data: JSON.stringify({
                        where: whereClause,
                        date: data
                    })
                }
            }
        }));
    }

    useEffect(() => {
        if (schedulesRequestStatus === fetchStatus.IDLE) {
            let loc = user ? user.locationId : "";
            let where = JSON.parse(JSON.stringify(whereClause));
            where.location.value.push(loc);
            _loadSchedules(whereClause);
        }
    }, [schedulesRequestStatus, dispatch]);

    useEffect(() => {
        // if (schedulesRequestStatus === fetchStatus.IDLE) {
        let loc = user ? user.locationId : "";
        let where = JSON.parse(JSON.stringify(whereClause));
        where.location.value.push(loc);
        _loadSchedules(whereClause);
        // }
    }, [data]);

    const onAppointmentSelected = (id, appointmentData) => {
        let data = {
            id: id,
            leadId: "",
            leadName: "",
            locationId: "",

        }
        dispatch(updateRecentClickedAppointment(data));


        // let _appointmentObj = Object.assign({}, appointment);
        // _appointmentObj.leadId = recentClickedAppointmentData.leadId;
        // _appointmentObj.leadName = recentClickedAppointmentData.leadName;
        // _appointmentObj.locationId = recentClickedAppointmentData.locationId;

        // let _newAppointmentObj = _appointmentObj;
        // dispatch(updateSingleAppointmentData({
        //     appointment: _newAppointmentObj,
        // }));

        // let appointmentObj = {
        //     "leadId": "reww",
        //     "leadName": "dffsdfsf",
        //     "calendarData": {
        //         "Subject": "sdfsdf",
        //         "Status": "",
        //         "StartTime": "",
        //         "EndTime": "",
        //         "Description": "",
        //         "MeetingWith": "",
        //         "Feedback": "",
        //         "StartTimezone": null,
        //         "EndTimezone": null,
        //         "Id": 3
        //     },
        //     "locationId": ""
        // }
        let appointmentObj = {
            "leadId": appointmentData.leadId,
            "leadName": appointmentData.leadName,
            "calendarData": appointmentData.calendarData,
            "locationId": appointmentData.locationId
        }
        dispatch(updateSingleAppointmentData({
            appointment: appointmentObj,
        }));

        history.push('appointmentDetails');

    };

    const onAppointmentPhoneClicked = (e, phoneNumber) => {
        e.stopPropagation();
        if (phoneNumber != '') {
            const href = `tel:${phoneNumber}`;
            window.location.href = href;
        }
    }

    const onAppointmentMapClicked = (e, placeId) => {
        e.stopPropagation();
        if (placeId != '') {
            window.location.href = "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" + placeId;
        }
    }

    const onAppointmentImHereClicked = (e, placeId) => {
        e.stopPropagation();
    }


    //----- MAP -----
    const [loadMap, setLoadMap] = useState(false);

    useEffect(() => {
        loadGoogleMapScript(() => {
            setLoadMap(true)
        });
    }, []);


    // GetRouteSchedules
    const _loadRouteSchedules = async () => {
        if (user.id) {
            await dispatch(getRouteSchedules({
                model: {
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "GETROUTESCHEDULES",
                    payload: {
                        id: user.id,
                        data: JSON.stringify({
                            date: Moment(Moment(new Date())).format('YYYY-MM-DD')
                        })
                    }
                }
            }));
        }
    }

    const routeSchedules = useSelector(selectRouteSchedules);
    const routeSchedulesStatus = useSelector(selectRouteSchedulesStatus);

    useEffect(() => {
        if (routeSchedulesStatus === fetchStatus.IDLE) {
            _loadRouteSchedules();
        }
    }, [routeSchedulesStatus]);

    const [markerList, setMarkerList] = useState(undefined);

    useEffect(() => {
        if (routeSchedules) {
            let _routeSchedules = routeSchedules.map((d, index) => {
                return {
                    lat: parseFloat(d.latitude),
                    lng: parseFloat(d.longitude),
                    title: d.leadName && d.leadName,
                    label: `${index + 1}`
                };
            });
            setMarkerList(_routeSchedules);
        }
    }, [routeSchedules])

    const [googleWaypoints, setGoogleWaypoints] = useState();

    const onDirectionClicked = (e) => {
        e.preventDefault();

        if (routeSchedules) {
            const utcTimestamp = new Date().getTime();
            let _routeSchedules = routeSchedules.filter((d) => d.utcFormatStartTime > utcTimestamp);
            let strWaypointsName = _routeSchedules.map(obj => obj.leadName).join('%7C');
            let strWaypointsID = _routeSchedules.map(obj => obj.place_id).join('%7C');
            let googleMaplink = "https://www.google.com/maps/dir/?api=1&travelmode=driving&waypoints=" + strWaypointsName + "&waypoint_place_ids=" + strWaypointsID;
            window.location.href = googleMaplink;
        }
    }

    const handleRefresh = (event) => {
        _loadRouteSchedules();
        _loadSchedules();
        event.detail.complete();
    }

    const getAppointmentStatusClass = (status) => {
        if (status === "Done") {
            return 'appointment-status-done';
        } else if (status === "Rejected") {
            return 'appointment-status-rejected';
        }
    }

    return (
        <IonPage>
            {schedulesRequestStatus === fetchStatus.LOADING ||
                routeSchedulesStatus === fetchStatus.LOADING ? <div className="process-loader">
                <GridLoader />
            </div> : <></>}
            <TopNavBar
                pageTitle="All Appointments" />
            <IonContent>

                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>

                <IonRow className="ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol size="8">
                        <IonLabel className="c-heading">Your Schedule</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-margin-start ion-margin-end">
                    <IonCol>
                        <WeekCalendar showDetailsHandle={showDetailsHandle} />
                    </IonCol>
                </IonRow>
                <IonRow className="ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol size="8">
                        <IonLabel className="c-heading">Appointments</IonLabel>
                    </IonCol>
                </IonRow>

                <IonRow className="ion-margin-start ion-margin-end">
                    <IonCol size="6">
                        <IonButton
                            className={`c-btn-list ${activeMenu === 'list' && 'c-btn-list-active'}`}
                            fill="clear"
                            onClick={e => setActiveMenu("list")}>
                            List
                        </IonButton>
                    </IonCol>
                    {/* <IonCol size="6">
                        <IonButton
                            className={`c-btn-list ${activeMenu === 'route' && 'c-btn-list-active'}`}
                            fill="clear"
                            onClick={e => setActiveMenu("route")}>
                            Route Scheduler
                        </IonButton>
                    </IonCol> */}
                    <IonCol size="6">
                        <IonButton
                            className={`c-btn-list ${activeMenu === 'route' && 'c-btn-list-active'}`}
                            fill="clear"
                            onClick={(e) => onDirectionClicked(e)}>
                            Route Navigate
                        </IonButton>
                    </IonCol>
                </IonRow>

                {activeMenu === 'list' ?
                    appointmentsList && appointmentsList.map((d) => {
                        return <IonRow key={d.id}>
                            <div className="appointment-card" onClick={(e) => onAppointmentSelected(d.id, d)}>
                                <div className="appointment-card-header-container">
                                    <div className="appointment-card-title">
                                        {d.leadName}
                                    </div>
                                    <IonButtons slot="end">
                                        <button className="custom-button-map c-mr-3" onClick={(e) => onAppointmentMapClicked(e, d.place_id)}>
                                            <img src={mapImg} alt="My Image" />
                                        </button>
                                        <IonButton
                                            className="c-btn-circle appointment-card-phone-btn"
                                            fill="clear"
                                            onClick={(e) => onAppointmentPhoneClicked(e, d.LeadPhone)}>
                                            <IonIcon icon={callOutline}></IonIcon>
                                        </IonButton>

                                    </IonButtons>
                                </div>
                                <div className="appointment-card-body">
                                    <div className="appointment-card-body-container-1">
                                        <div className="c-container c-mr-3">
                                            <IonIcon className="c-circle" icon={calendarClear} />
                                        </div>
                                        <div className="ml-2">
                                            <span className="appointment-card-tracking-tilte">{d.calendarData.Subject}</span>
                                            <div className="appointment-card-tracking-sub-tilte">{d.calendarData.Description}</div>
                                        </div>
                                    </div>
                                    <hr className="appointment-card-container-border" />
                                    {/* <div className="appointment-card-body-container-2"> */}
                                    <div className={`appointment-card-body-container-2 ${getAppointmentStatusClass(d.calendarData.Status)}`}>
                                        <IonRow className="ion-justify-content-between ion-align-items-center">
                                            <IonCol size="auto" className="c-p-0">
                                                <div className="appointment-card-start-time-text">
                                                    <IonIcon aria-hidden="true" className="c-mr-2" icon={timeOutline} />
                                                    {toLocalTimeOnly(d.calendarData.StartTime)}
                                                </div>
                                            </IonCol>
                                            <IonCol size="auto">
                                                <div className="appointment-card-start-time-text">
                                                    {d.calendarData.Status}
                                                    {/* {d.calendarData.Status === "Open" && <IonIcon aria-hidden="true" className="c-ml-2" icon={business} />}
                                                    {d.calendarData.Status === "Done" && <IonIcon aria-hidden="true" className="c-ml-2" icon={checkmarkDone} />}
                                                    {d.calendarData.Status === "Rejected" && <IonIcon aria-hidden="true" className="c-ml-2" icon={closeCircleOutline} />} */}
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </div>
                                </div>
                            </div>
                        </IonRow>
                    })
                    :
                    <>
                        {/* <IonRow>
                            <IonCol size="12">
                                {!loadMap ? <div>Loading...</div> : <GMapWaypointsDirections markerList={markerList} />}
                            </IonCol>
                        </IonRow> */}
                        <IonRow>
                            <IonCol size="auto">
                                <IonItem lines="none">
                                    <IonLabel>Navigate</IonLabel>
                                    <IonButton fill="clear" color="primary" onClick={(e) => onDirectionClicked(e)}>
                                        <IonIcon className="button-icon" slot="icon-only" icon={navigate} />
                                    </IonButton>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </>
                }




            </IonContent>
            <BootomNavBar active="appointment" />
        </IonPage>
    )
}

export default Appointment;