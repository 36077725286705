// function calculateDistance(lat1, lon1, lat2, lon2) {
//     const R = 6371; // Radius of the earth in kilometers
//     const dLat = deg2rad(lat2 - lat1);
//     const dLon = deg2rad(lon2 - lon1);
//     const a =
//       Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//       Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
//       Math.sin(dLon / 2) * Math.sin(dLon / 2);
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//     const distance = R * c; // Distance in kilometers
//     return distance;
//   }
  
//   function deg2rad(deg) {
//     return deg * (Math.PI / 180);
//   }
  
//   function calculatePathDistance(path) {
//     let totalDistance = 0;
//     for (let i = 0; i < path.length - 1; i++) {
//       const [lat1, lon1] = path[i];
//       const [lat2, lon2] = path[i + 1];
//       const distance = calculateDistance(lat1, lon1, lat2, lon2);
//       totalDistance += distance;
//     }
//     return totalDistance;
//   }
  
//   function permute(locations) {
//     if (locations.length === 1) {
//       return [locations];
//     }
  
//     const permutations = [];
  
//     for (let i = 0; i < locations.length; i++) {
//       const currentLocation = locations[i];
//       const remainingLocations = locations.slice(0, i).concat(locations.slice(i + 1));
//       const remainingPermutations = permute(remainingLocations);
  
//       for (let j = 0; j < remainingPermutations.length; j++) {
//         const permutedArray = [currentLocation].concat(remainingPermutations[j]);
//         permutations.push(permutedArray);
//       }
//     }
  
//     return permutations;
//   }
  
//   function shortestPath(locations) {
//     const permutations = permute(locations);
//     let shortestDistance = Infinity;
//     let shortestPath = [];
  
//     for (let i = 0; i < permutations.length; i++) {
//       const currentPath = permutations[i];
  
//       // Check if the starting point is the first point in the current path
//       const startingPoint = locations[0];
//       const currentStartingPoint = currentPath[0];
//       if (startingPoint[0] !== currentStartingPoint[0] || startingPoint[1] !== currentStartingPoint[1]) {
//         // Skip this path if the starting point is not the first point
//         continue;
//       }
  
//       const currentDistance = calculatePathDistance(currentPath);
  
//       if (currentDistance < shortestDistance) {
//         shortestDistance = currentDistance;
//         shortestPath = currentPath;
//       }
//     }
  
//     return shortestPath;
//   }
  
//   // Example usage
//   const locations = [
//     [22.280153275484054, 70.76376272041553], // crystall
//     [22.301139344676187, 70.76810445248032],      // raiya
//     [22.286582984248064, 70.77173321214623],     // kkv
//     [22.300234303566075, 70.77555002125138], // human
//     [22.27407694530524, 70.75724367110249]  // mcd
   
//   ];
  
//   const path = shortestPath(locations);
//   console.log("Shortest path:", path);


//   export const getShortestPath = (locationsArr) => {
//     return shortestPath(locationsArr);
// };






function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  
  function calculatePathDistance(path) {
    let totalDistance = 0;
    for (let i = 0; i < path.length - 1; i++) {
      const [lat1, lon1] = path[i];
      const [lat2, lon2] = path[i + 1];
      const distance = calculateDistance(lat1, lon1, lat2, lon2);
      totalDistance += distance;
    }
    return totalDistance;
  }
  
  function permute(locations) {
    if (locations.length === 1) {
      return [locations];
    }
  
    const permutations = [];
  
    for (let i = 0; i < locations.length; i++) {
      const currentLocation = locations[i];
      const remainingLocations = locations.slice(0, i).concat(locations.slice(i + 1));
      const remainingPermutations = permute(remainingLocations);
  
      for (let j = 0; j < remainingPermutations.length; j++) {
        const permutedArray = [currentLocation].concat(remainingPermutations[j]);
        permutations.push(permutedArray);
      }
    }
  
    return permutations;
  }
  
  function shortestPath(locations) {
    const permutations = permute(locations);
    let shortestDistance = Infinity;
    let shortestPath = [];
  
    for (let i = 0; i < permutations.length; i++) {
      const currentPath = permutations[i];
  
      // Check if the starting point is the first point in the current path
      const startingPoint = locations[0];
      const currentStartingPoint = currentPath[0];
      if (startingPoint[0] !== currentStartingPoint[0] || startingPoint[1] !== currentStartingPoint[1]) {
        // Skip this path if the starting point is not the first point
        continue;
      }
  
      const currentDistance = calculatePathDistance(currentPath);
  
      if (currentDistance < shortestDistance) {
        shortestDistance = currentDistance;
        shortestPath = currentPath;
      }
    }
  
    return shortestPath;
  }
  
  // Example usage
//   const locations = [
//     [22.280153275484054, 70.76376272041553], // crystall
//     [22.301139344676187, 70.76810445248032],      // raiya
//     [22.286582984248064, 70.77173321214623],     // kkv
//     [22.300234303566075, 70.77555002125138], // human
//     [22.27407694530524, 70.75724367110249]  // mcd
   
//   ];
  
//   const path = shortestPath(locations);
//   console.log("Shortest path:", path);


  export const getShortestPath = (locationsArr) => {
    return shortestPath(locationsArr);
};