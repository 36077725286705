import React, { useEffect } from "react";
import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonTitle, IonToolbar } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";

import { addOutline, callOutline } from 'ionicons/icons';
import TopNavBar from "../../../components/navbars/topNavBar";
import { createNewLead, getAllLeads, selectAllLeads, selectStatus, selectTotalItems, updateRecentClickedLead } from "../reducerSlices/leadSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatus, tenantId } from "../../../app/api/client";
import { accountService } from "../../accounts/services/accountService";
import BootomNavBar from "../../../components/navbars/bootomNavBar";
import GridLoader from "../../../components/loaders/gridLoader";

function Lead() {


    const history = useHistory();
    const dispatch = useDispatch();

    const totalPageItems = useSelector(selectTotalItems);
    const leads = useSelector(selectAllLeads);
    const leadsRequestStatus = useSelector(selectStatus);

    const user = accountService.getAuthenticatedUser();

    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);

    const [items, setItems] = useState([]);
    const [offset, setOffset] = useState(0);

    const [searchQuery, setSearchQuery] = useState('');

    const _loadLeads = async (page, offset, limit, whereClause, sort) => {
        // let _whereClause = whereClause;
        // _whereClause.name = searchQuery;

        await dispatch(getAllLeads({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLEADS",
                payload: {
                    id: "",
                    data: JSON.stringify({
                        offset: offset,
                        limit: limit,
                        where: whereClause,
                        sort: sort
                    })
                }
            }
        }));
    }

    let where = { "location": { "value": [], "text": "All" }, "leadStatus": { "value": [], "text": "All" }, "name": "" };
    let sort = {
        "by": 1,
        "ascending": false
    };


    useEffect(() => {
        if (leadsRequestStatus === fetchStatus.IDLE) {
            // let loc = user ? user.locationId : "";
            // let whereClause = { "offset": 0, "limit": 10, "where": { "location": { "value": ["838EB11A-4A9E-4C97-8C38-F369B869CB21"], "text": "All" }, "leadStatus": { "value": [], "text": "All" }, "name": "" }, "sort": { "by": 1, "ascending": false } };
            // let where = JSON.parse(JSON.stringify(whereClause));

            // where.location.value.push(loc);
            // dispatch(updateLocationFilter({
            //     locations: locationOptions,
            //     value: loc
            // }));
            // dispatch(updateIsFiltered());

            _loadLeads(0, 0, 10, where, sort);
        }
    }, [leadsRequestStatus]);

    useEffect(() => {
        if (leadsRequestStatus === fetchStatus.SUCCEEDED) {

            if (offset + 10 < totalPageItems) {
                setDisableInfiniteScroll(false);
            } else {
                setDisableInfiniteScroll(true);
            }

            if (offset < totalPageItems) {
                setOffset(offset + 10);
            }
        }
    }, [leadsRequestStatus]);

    useEffect(() => {
        if (leads) {
            setItems([...items, ...leads]);
        }

    }, [leads]);


    const onLeadSelected = (id) => {
        dispatch(updateRecentClickedLead(id));
        history.push('leadDetails');
    };

    const onAddRecordClicked = (e) => {
        dispatch(createNewLead());
        dispatch(updateRecentClickedLead(0));
        history.push("/leadDetails");
    }

    const generateItems = () => {
        if (offset < totalPageItems) {
            _loadLeads(0, offset, 10, where, sort);
        }
    };

    const handleRefresh = (event) => {
        event.detail.complete();
        window.location.reload();
    }


    return (
        <IonPage>
            {leadsRequestStatus === fetchStatus.LOADING ? <div className="process-loader">
                <GridLoader />
            </div> : <></>}
            <TopNavBar
                // pageTitle={`All Leads ${totalPageItems}`} />
                pageTitle={`All Leads`} />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>

                <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol size="4">
                        <IonLabel className="c-heading">Pipeline</IonLabel>
                    </IonCol>
                    <IonCol size="auto">
                        <IonButton
                            className="c-add-btn"
                            fill="clear"
                            onClick={(e) => onAddRecordClicked(e)}>
                            <IonIcon slot="icon-only" icon={addOutline}></IonIcon> New&nbsp;
                        </IonButton>
                    </IonCol>
                </IonRow>

                {/* <IonRow className="ion-justify-content-between c-m-1">
                    <IonCol size="12">
                        <IonSearchbar className="c-ion-searchbar"></IonSearchbar>
                    </IonCol>
                </IonRow> */}
                {/* <IonRow className="ion-justify-content-between c-m-1">
                    <IonCol size="12">
                        <IonSearchbar className="c-ion-searchbar" value={searchQuery} onIonChange={e => setSearchQuery(e.detail.value)}></IonSearchbar>
                    </IonCol>
                </IonRow> */}


                {
                    items && items.map((d, index) => {
                        return <IonCard className="c-lead-card" key={d.id} onClick={() => onLeadSelected(d.id)}>
                            <IonCardHeader>
                                <IonCardTitle>{d.LeadName}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                {d.LeadPhone &&
                                    <a target="_blank" onClick={(e) => e.stopPropagation()} href={`tel:${d.LeadPhone}`}>{d.LeadPhone}</a>
                                }
                            </IonCardContent>
                        </IonCard>
                    })
                }
                {/* Lead
                {
                    leads && leads.map((d, index) => {
                        // items && items.map((d, index) => {
                        return <IonCard onClick={() => onLeadSelected(d.id)}>
                            <IonCardHeader>
                                <IonCardTitle>{index} {d.LeadName}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                {d.LeadPhone}
                            </IonCardContent>
                        </IonCard>
                    })
                } */}


                <IonInfiniteScroll
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(ev) => {
                        generateItems();
                        setTimeout(() => ev.target.complete(), 500);
                    }}
                >
                    <IonInfiniteScrollContent loadingText="Please wait..." loadingSpinner="bubbles"></IonInfiniteScrollContent>
                </IonInfiniteScroll>

            </IonContent>

            <BootomNavBar active="lead" />
        </IonPage>
    )
}

export default Lead;