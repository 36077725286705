export const sections = {
    APPOINTMENT_DETAILS: 0,
    CALENDARDATA_DETAILS: 1,
};

export const onValueChanged = (appointment, e, section, value) => {
    let _appointment = JSON.parse(JSON.stringify(appointment));
    if (section === sections.APPOINTMENT_DETAILS) {
        return _saveAppointmentDetails(_appointment, e, value);
    }
    else if (section == sections.CALENDARDATA_DETAILS) {
        return _saveCalendarData(_appointment, e, value);
    }
};


const _saveAppointmentDetails = (appointment, e, value) => {
    // if (e.target.name === "txtLeadId") {
    //     appointment.leadId = e.target.value;
    // } else if (e.target.name === "txtLeadName") {
    //     appointment.leadName = e.target.value;
    // }

    return appointment;
};

const _getUTCFormat = (value) => {
    try {


        const localDate = new Date(value);
        const utcYear = localDate.getUTCFullYear();
        const utcMonth = localDate.getUTCMonth() + 1; // Adding 1 because months are zero-based
        const utcDay = localDate.getUTCDate();
        const utcHours = localDate.getUTCHours();
        const utcMinutes = localDate.getUTCMinutes();
        const utcSeconds = localDate.getUTCSeconds();

        const utcValue = `${utcYear}-${utcMonth.toString().padStart(2, '0')}-${utcDay.toString().padStart(2, '0')}T${utcHours.toString().padStart(2, '0')}:${utcMinutes.toString().padStart(2, '0')}:${utcSeconds.toString().padStart(2, '0')}Z`;
        return utcValue;
    } catch { }
}

const _saveCalendarData = (appointment, e, value) => {
    // console.log('_saveCalendarData', e)
    // console.log('_saveCalendarData', e.target.name)
    // console.log('_saveCalendarData',  e.detail.value)
    // console.log('_saveCalendarData',  value)
    if (e.target.name === "txtSubject") {
        appointment.calendarData.Subject = e.target.value;
    }
    else if (e.target.name === "drpStatus") {
        appointment.calendarData.Status = e.target.value;
    }
    else if (e.target.name === "txtStartTime") {
        if (!appointment.calendarData.StartTime) {
            appointment.calendarData.StartTime = "";
        }
        let utcValue = _getUTCFormat(value);
        appointment.calendarData.StartTime = utcValue;
        // appointment.calendarData.StartTime = value;
    }
    else if (e.target.name === "txtEndTime") {
        if (!appointment.calendarData.EndTime) {
            appointment.calendarData.EndTime = "";
        }
        let utcValue = _getUTCFormat(value);
        appointment.calendarData.EndTime = utcValue;
        // appointment.calendarData.EndTime = value;
    }
    else if (e.target.name === "txtDescription") {
        appointment.calendarData.Description = e.target.value;
    }
    else if (e.target.name === "txtMeetingWith") {
        appointment.calendarData.MeetingWith = e.target.value;
    }
    else if (e.target.name === "drpFeedback") {
        appointment.calendarData.Feedback = e.target.value;
    }

    return appointment;
};

export const _saveAppointmentEndTime = (appointment) => {

    if(appointment.calendarData.StartTime !== ""){
        let calculateEndTime = new Date(appointment.calendarData.StartTime);
        // Add 30 minutes
        calculateEndTime.setMinutes(calculateEndTime.getMinutes() + 30);
        
        if (!appointment.calendarData.EndTime) {
            appointment.calendarData.EndTime = "";
        }
        let utcValue = _getUTCFormat(calculateEndTime);
        appointment.calendarData.EndTime = utcValue;
    }

    return appointment;
};
