import React, { useEffect } from "react";
import './../css/login.css';
import { IonButton, IonCol, IonContent, IonHeader, IonInput, IonItem, IonPage, IonRow, IonTitle, IonToolbar, IonToast } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { GetVersion, selectLoginError, selectLoginStatus, selectVersion, selectVersionStatus, updateLoginStatus } from '../reducerSlices/accountsSlice';
import { accountService } from "../services/accountService";
import { fetchStatus, tenantId } from "../../../app/api/client";

function Login() {
    const dispatch = useDispatch();
    const history = useHistory();

    const loginStatus = useSelector(selectLoginStatus);
    const loginError = useSelector(selectLoginError);

    const version = useSelector(selectVersion);
    const versionStatus = useSelector(selectVersionStatus);

    const [appVersion, setAppVersion] = useState("1.0.11");
    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [showNotFoundToast, setShowNotFoundToast] = useState(false);
    const [showCRMAccessToast, setShowCRMAccessToast] = useState(false);
    const [aumAlert, setAumAlert] = useState(false);

    const isModelValid = [email, password].every(Boolean);

    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
    };

    const onLoginClick = (e) => {
        accountService.login({ client: tenantId, email: email, password: password, authenticationType: "TOKEN" });
    }

    const onInputChanged = (e) => {
        if (e.target.name === "email") {
            setemail(e.target.value);
        }
        else {
            setPassword(e.target.value);
        }
    };

    const onToastDismissed = (e) => {
        setShowToast(false);
    };

    const onToastNotFoundDismissed = (e) => {
        setShowNotFoundToast(false);
    };

    useEffect(() => {
        if (versionStatus === fetchStatus.SUCCEEDED) {
            if (version !== "") {
                if (appVersion !== version) {
                    //InAppBrowser.create('');
                    setAumAlert(true);
                }
            }
        }
    }, [version, appVersion]);

    useEffect(() => {
        if (versionStatus === fetchStatus.SUCCEEDED) {
            if (version !== "") {
                if (appVersion !== version) {
                    //InAppBrowser.create('');
                    setAumAlert(true);
                }
            }
        }
        else if (versionStatus === fetchStatus.IDLE) {
            dispatch(GetVersion());
        }

    }, [versionStatus, dispatch]);

    useEffect(() => {
        if (loginStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateLoginStatus());
                if (accountService.isAdmin() || accountService.isSalesManager() || accountService.isSalesRep() || accountService.isStephanie() || accountService.isCRMAccess()) {
                    history.push("/appointment");
                } else {
                    setShowCRMAccessToast(true);
                    accountService.logout();
                    history.push("/login");
                }
            }
        }
    }, [loginStatus, dispatch, history]);

    useEffect(() => {
        if (loginStatus === fetchStatus.FAILED) {
            setShowToast(true);
        }
    }, [loginStatus]);

    return (
        <IonPage>

            <IonContent>
                <div className="login-container">
                    <div className="login-controls">
                        <IonRow>
                            <IonCol>
                                <div className="login-title-container">
                                    <div className="ion-text-center">
                                        <div className="login-title">Welcome</div>
                                    </div>
                                    <div className="ion-text-center">
                                        <div className="login-sub-title">Lets get busy!</div>
                                    </div>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonInput
                                        className="custom-input"
                                        type="text"
                                        name="email"
                                        onIonInput={e => onInputChanged(e)}
                                        value={email}
                                        placeholder="Email"></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-margin-top ion-margin-bottom">
                            <IonCol>
                                <IonItem lines="none" >
                                    <IonInput
                                        className="custom-input"
                                        type="password"
                                        name="password"
                                        value={password}
                                        onIonInput={e => onInputChanged(e)}
                                        placeholder="Password"></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="10" className="ion-text-center">
                                <IonButton
                                    className="login-btn"
                                    fill="clear"
                                    expand="block"
                                    onClick={(e) => onLoginClick(e)}>
                                    Login
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>

                <IonToast
                    position="bottom"
                    header="Error"
                    color="danger"
                    isOpen={showToast}
                    onDidDismiss={(e) => onToastDismissed(e)}
                    message={loginError}
                    duration={2000}
                />
                <IonToast
                    position="bottom"
                    header="Error"
                    color="danger"
                    isOpen={showNotFoundToast}
                    onDidDismiss={(e) => onToastNotFoundDismissed(e)}
                    message={"Auth details not found"}
                    duration={2000}
                />
                <IonToast
                    position="top"
                    header="Authentication failure"
                    color="warning"
                    isOpen={showCRMAccessToast}
                    onDidDismiss={() => onToastDismissed()}
                    message="To gain access to the CRM, kindly get in touch with the administrator."
                    duration={2000}
                />
            </IonContent>
        </IonPage>
    )
}

export default Login;