import React, { useEffect, useRef } from "react";
import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToast, IonToolbar } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";

import { callOutline, navigate, calendarClear, timeOutline, cameraOutline } from 'ionicons/icons';
import TopNavBar from "../../../components/navbars/topNavBar";
import { createNewAppointment, saveLeadRoute, selectModificationLeadRouteError, selectModificationLeadRouteStatus, updateRecentClickedAppointment, updateSingleAppointmentData } from "../../lead/reducerSlices/leadSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllShedules, getLeadRoute, getRouteSchedules, selectAllSchedules, selectLeadRoute, selectLeadRouteStatus, selectRouteSchedules, selectRouteSchedulesStatus, selectStatus, updateCalanderDate } from "../reducerSlices/scheduleSlice";
import { fetchStatus, tenantId } from "../../../app/api/client";
import { accountService } from "../../accounts/services/accountService";
import WeekCalendar from "../../../components/calendar/weekCalendar";
import { _getUtcTimestamp, toLocalDate, toLocalDateOnly, toLocalTimeOnly } from "../../../app/common/common";
import Moment from 'moment';

import mapImg from "./../../../assets/map-icon.png";
import BootomNavBar from "../../../components/navbars/bootomNavBar";

import GMap from '../../../components/map/GMap';
import GMapWaypointsDirections from "../../../components/map/GMapWaypointsDirections";
import GridLoader from "../../../components/loaders/gridLoader";
import Statement from "../../../components/controls/textboxes/statement";
import SaveButton from "../../../components/buttons/saveButton";
import EllipsisLoader from "../../../components/loaders/ellipsisLoader";
import { isFormValid } from "../../../components/controls/services/formService";
import axios from "axios";
import { getShortestPath } from "../../../app/common/shortestPath";
import { saveUserLocation, selectLiveLocation } from "../../locations/reducerSlices/locationSlice";
import { CameraResultType, CameraSource } from '@capacitor/camera';
import { useCamera } from '@ionic/react-hooks/camera';
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import nophoto from '../../../assets/office-nophoto.png';

const GOOGLE_MAP_API_KEY = 'AIzaSyAakzSaZd7t-EBQkMQOu5U3QzhHV4yr1CY';
// load google map script
const loadGoogleMapScript = (callback) => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
        callback();
    } else {
        // const googleMapScript = document.createElement("script");
        // googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
        // window.document.body.appendChild(googleMapScript);
        // googleMapScript.addEventListener("load", callback);
    }
}

function RouteList() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { getPhoto } = useCamera();

    const userDeatils = accountService.getAuthenticatedUser();

    const leadRoute = useSelector(selectLeadRoute);
    const leadRouteRequestStatus = useSelector(selectLeadRouteStatus);
    const [leadRouteList, setLeadRouteList] = useState();

    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const liveLocation = useSelector(selectLiveLocation);

    const modificationLeadRouteRequestStatus = useSelector(selectModificationLeadRouteStatus);
    const LEAD_ROUTE_LIST_FORM = "LeadRouteListForm";
    const [lrlfSubmitted, setLRLFSubmitted] = useState(false);
    const [note, setNote] = useState("");
    const [checkOutLeadRouteId, setCheckOutLeadRouteId] = useState("");
    const [checkOutLeadRouteLeadId, setCheckOutLeadRouteLeadId] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [showSubmitToast, setShowSubmitToast] = useState(false);
    const [showSubmitToastError, setShowSubmitToastError] = useState(false);
    const modificationLeadRouteError = useSelector(selectModificationLeadRouteError);

    const _loadLeadRoute = async () => {
        await dispatch(getLeadRoute({
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLEADROUTEFORMOBILEAPP",
                payload: {
                    id: "",
                    data: JSON.stringify({ routeDate: Moment(new Date).format('YYYY-MM-DD') })
                }
            }
        }));
    }

    useEffect(() => {
        if (leadRouteRequestStatus === fetchStatus.IDLE) {
            _loadLeadRoute();
        }
    }, [leadRouteRequestStatus]);

    useEffect(() => {
        if (leadRoute) {
            let _leadRoute = leadRoute.map(d => ({ ...d, "utcFormatStartTime": _getUtcTimestamp(d.TimeStamp) }));
            _leadRoute.sort((a, b) => {
                return a.utcFormatStartTime - b.utcFormatStartTime;
            });
            setLeadRouteList(_leadRoute);
        }
    }, [leadRoute]);


    const savePicture = async (photo, fileName) => {
        let base64Data = await base64FromPath(photo.webPath);
        return {
            filepath: fileName,
            webviewPath: photo.webPath,
            data: base64Data
        };
    };

    // --------- Place Photo ---------
    const [base64ImagePlace, setbase64ImagePlace] = useState("");
    const [imagePlacePhotoURL, setImagePlacePhotoURL] = useState("");

    const takePlacePhoto = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 20
        });

        const fileName = new Date().getTime() + '.jpg';
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhoto = savedFileImage;

        // setbase64ImagePlace(newPhoto.data);
        let result = newPhoto.data.split(',').pop();
        setbase64ImagePlace(result);

        console.log('newPhoto', newPhoto)
        setImagePlacePhotoURL(newPhoto.webviewPath);
    };

    const _saveUserLocation = (latitude, longitude) => {
        const _locationData = { latitude, longitude };
        if (latitude != '' && longitude != '') {
            if (userDeatils.id) {
                dispatch(saveUserLocation({
                    client: tenantId,
                    operation: "SAVEUSERLOCATION",
                    payload: {
                        id: userDeatils.id,
                        data: JSON.stringify({
                            location: JSON.stringify(_locationData),
                            trackingType: "routeVisited"
                        })
                    }
                }));
            }
        }
    }

    const onSaveLeadRoute = (id, checkOutLeadRouteLeadId) => {
        if (id && checkOutLeadRouteLeadId) {
            if (isFormValid(LEAD_ROUTE_LIST_FORM) && base64ImagePlace) {
                setLRLFSubmitted(false);
                let details = {
                    leadId: checkOutLeadRouteLeadId,
                    note: note,
                    placePhoto: {
                        contentType: "image/jpg",
                        length: 0,
                        photoData: base64ImagePlace
                    },
                    visitedlocation: {
                        latitude: latitude,
                        longitude: longitude
                    }
                }
                dispatch(saveLeadRoute({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    // operation: "SAVELEADROUTE",
                    operation: "SAVEROUTETRACKINGFORMOBILEAPP",
                    payload: {
                        id: id,
                        data: JSON.stringify(details)
                    }
                }));
            } else {
                setLRLFSubmitted(true);
            }
        }
    }

    useEffect(() => {
        if (modificationLeadRouteRequestStatus === fetchStatus.SUCCEEDED) {
            // _saveUserLocation(latitude, longitude)
            setShowSubmitToast(true);
            _loadLeadRoute();
            closeModal();
        }
        if (modificationLeadRouteRequestStatus === fetchStatus.FAILED) {
            setShowSubmitToastError(true);
        }
        if (modificationLeadRouteRequestStatus === fetchStatus.LOADING) {
            setShowSubmitToastError(false)
        }
    }, [modificationLeadRouteRequestStatus])

    const openModal = (routeTrackingId, leadId) => {
        setCheckOutLeadRouteId(routeTrackingId);
        setCheckOutLeadRouteLeadId(leadId);
        setNote("");
        setIsOpen(true);
    };

    const closeModal = () => {
        setCheckOutLeadRouteId("");
        setCheckOutLeadRouteLeadId("");
        setbase64ImagePlace("");
        setImagePlacePhotoURL("");
        setLRLFSubmitted(false);
        setNote("");
        setIsOpen(false);
    };

    useEffect(() => {
        if (liveLocation) {
            setLatitude(liveLocation.latitude);
            setLongitude(liveLocation.longitude);
        }
    }, [liveLocation]);

    const onDirectionLeadRouteClicked = (e) => {
        e.preventDefault();
        if (leadRoute && latitude && longitude) {
            const _leadRoute = leadRoute.map(d => [d.lat, d.lng, { 'place_id': d.place_id }, { 'leadName': d.LeadName }]);
            if (latitude && longitude) {
                const currentLocation = [[latitude, longitude, { 'place_id': '' }, { 'leadName': 'current loaction' }]];
                const _leadRouteCurrentLocation = [...currentLocation, ..._leadRoute];
                const shortestPathRoute = getShortestPath(_leadRouteCurrentLocation);
                shortestPathRoute.shift();
                const strWaypointsID = shortestPathRoute.map(([lat, lng, { place_id }]) => place_id).join('%7C');
                const strWaypointsName = shortestPathRoute.map(([lat, lng, { place_id }, { leadName }]) => leadName).join('%7C');
                let googleMaplink = "https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=" + latitude + "," + longitude + "&waypoints=" + strWaypointsName + "&waypoint_place_ids=" + strWaypointsID;
                window.location.href = googleMaplink;
            }
        }
    }


    const handleRefresh = (event) => {
        _loadLeadRoute();
        event.detail.complete();
    }


    return (
        <IonPage>
            {leadRouteRequestStatus === fetchStatus.LOADING ? <div className="process-loader">
                <GridLoader />
            </div> : <></>}
            <TopNavBar
                pageTitle="Route List" />
            <IonContent>
                <IonRow className="ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol size="8">
                        <IonLabel className="c-heading">Route List</IonLabel>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol size="12">
                        <IonItem lines="none" className="ion-text-end">
                            <IonLabel>Navigate</IonLabel>
                            <IonButton fill="clear" color="primary" onClick={(e) => onDirectionLeadRouteClicked(e)}>
                                <IonIcon className="button-icon" slot="icon-only" icon={navigate} />
                            </IonButton>
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>

                {
                    leadRouteList && leadRouteList.map((d) => {
                        return <IonCard key={d.id}>
                            <IonCardHeader>
                                <IonCardTitle>{d.LeadName}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        {d.LeadPhone &&
                                            <a target="_blank" href={`glip://r/call?number=${d.LeadPhone}`}>{d.LeadPhone}</a>
                                        }
                                    </IonCol>
                                    <IonCol className="ion-text-end">
                                        <IonButton
                                            className="c-add-btn"
                                            fill="clear"
                                            onClick={() => openModal(d.id, d.leadId)}>
                                            Visited
                                        </IonButton>
                                    </IonCol>
                                </IonRow>

                            </IonCardContent>
                        </IonCard>
                    })
                }

                <IonToast
                    position="top"
                    header="Submitted"
                    color="success"
                    isOpen={showSubmitToast}
                    message="Saved successfully"
                    duration={2000}
                />

                <IonToast
                    position="top"
                    header="Submitted"
                    color="danger"
                    isOpen={showSubmitToastError}
                    message={modificationLeadRouteError}
                    duration={2000}
                />


                <IonModal className="example-modal" isOpen={isOpen} onDidDismiss={closeModal}>
                    <IonContent>
                        <IonToolbar>
                            <IonTitle>Add note for visit</IonTitle>
                            <IonButtons slot="end">
                                <IonButton color="light" onClick={closeModal}>
                                    Close
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <IonList>
                            <IonItem lines="none">
                                <IonLabel position="stacked">Note</IonLabel>
                                <Statement
                                    id="txtDescription"
                                    name="txtDescription"
                                    value={note}
                                    rows={6}
                                    StatementClasses="form-control"
                                    onChange={(e) => setNote(e.target.value)}
                                    required={true}
                                    errorMessage="Please enter note"
                                    form={LEAD_ROUTE_LIST_FORM}
                                    submited={lrlfSubmitted}
                                    placeholder="Enter note..." />
                            </IonItem>

                        </IonList>

                        <IonRow className="ion-padding-start">
                            <IonCol size="12">
                                <IonButton
                                    className="c-btn-business-card"
                                    fill="clear"
                                    onClick={(e) => takePlacePhoto(e)}>
                                    <IonIcon slot="icon-only" icon={cameraOutline} className="c-pe-2"></IonIcon> Place Photo
                                </IonButton>
                            </IonCol>
                            {lrlfSubmitted && !base64ImagePlace &&
                                <IonCol size="12">
                                    <span className="error-label">
                                        <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                        {"Please take photo"}
                                    </span>
                                </IonCol>
                            }
                        </IonRow>

                        <IonRow className="ion-justify-content-center">
                            <IonCol size="10">
                                <div className="place-photo-container">
                                    <IonImg className="place-photo-container-photo" src={imagePlacePhotoURL || nophoto} />
                                </div>
                            </IonCol>
                        </IonRow>

                        <IonRow className="ion-justify-content-center">
                            {modificationLeadRouteRequestStatus !== fetchStatus.LOADING ?
                                <IonCol size="11">
                                    <SaveButton onSaveButtonClicked={(e) => onSaveLeadRoute(checkOutLeadRouteId, checkOutLeadRouteLeadId)} />
                                </IonCol>
                                : <EllipsisLoader />
                            }
                        </IonRow>
                    </IonContent>
                </IonModal>

            </IonContent>
            <BootomNavBar active="routeList" />
        </IonPage >
    )
}

export default RouteList;