import { IonAvatar, IonButton, IonButtons, IonCol, IonHeader, IonIcon, IonLabel, IonMenuToggle, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack, menuOutline, notificationsOutline } from 'ionicons/icons';
import React from 'react';
import { accountService } from '../../pages/accounts/services/accountService';
import BackButton from '../buttons/backButton';
import NotificationButton from '../buttons/notificationButton';
import boyFaceImg from '../../assets/boy-face.svg';
import { useHistory } from 'react-router';
import { createImageFromInitials, createImageFromInitialsBold, getRandomColor } from '../../app/common/common';

function TopNavBar(props) {
    const history = useHistory();
    const user = accountService.getAuthenticatedUser();

    const onLogoutClicked = (e) => {
        e.preventDefault();
        accountService.logout();
        window.location.reload();
        history.push('/login');
    };

    return (
        <IonHeader collapse="fade" className="ion-no-border">
            <IonToolbar className='top-nav'>
                <IonButtons slot="start">
                    {props.onBackButtonClicked &&
                        <BackButton
                            onBackButtonClicked={(e) => props.onBackButtonClicked && props.onBackButtonClicked(e)} />
                    }
                </IonButtons>
                <IonButtons slot="start">
                    {/* <img className="c-avatar" alt="Silhouette of a person's head" src={boyFaceImg} /> */}
                    <img className="c-avatar" src={createImageFromInitialsBold(50, user && user.firstName , '#0A263E')} alt={user && user.firstName } title={user && user.firstName } />
                </IonButtons>
                {/* <IonAvatar>
                    <img  alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                </IonAvatar> */}
                <IonTitle><span className='top-nav-bar-message'>Welcome</span><br /><span className="top-nav-bar-username">{user && user.firstName ? user.firstName : ""} {user && user.lastName ? user.lastName : ""}</span></IonTitle>
                {/* <IonButtons slot="end">
                    <NotificationButton />
                </IonButtons> */}
                <IonButtons slot="end">
                    {/* <IonButton
                        className="c-logout-btn"
                        fill="clear"
                        onClick={(e) => onLogoutClicked(e)}>
                        Logout
                    </IonButton> */}

                    <IonMenuToggle>
                        <IonButton fill="clear" color="dark">
                            <IonIcon icon={menuOutline} className=''></IonIcon>
                        </IonButton>

                    </IonMenuToggle>
                </IonButtons>


            </IonToolbar>
        </IonHeader >


        // <IonHeader>
        //     <IonRow className=" ">
        //         {props.onBackButtonClicked && <IonButtons>
        //             <BackButton
        //                 onBackButtonClicked={(e) => props.onBackButtonClicked && props.onBackButtonClicked(e)} />
        //         </IonButtons>
        //         }
        //         <IonAvatar size="small">
        //             <img style={{ padding: "10px" }} alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
        //         </IonAvatar>
        //         <IonTitle><span className='top-nav-bar-message'>Good morning</span><br /><span className="top-nav-bar-username">{user && user.firstName ? user.firstName : ""} {user && user.lastName ? user.lastName : ""}</span></IonTitle>
        //         <IonButtons>
        //             <NotificationButton />
        //         </IonButtons>
        //     </IonRow>
        // </IonHeader>
    );
}

export default TopNavBar;