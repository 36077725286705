import React, { useState, useEffect } from "react";
import { registerPlugin, Plugins } from "@capacitor/core";
import { updateLiveLocation } from "../../pages/locations/reducerSlices/locationSlice";
import { useDispatch } from "react-redux";
const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");

function BackgroundLocationService() {
    const dispatch = useDispatch();
    const [location, setLocation] = useState(null);

    useEffect(() => {
        const watcherOptions = {
            backgroundMessage: "Cancel to prevent battery drain.",
            backgroundTitle: "Tracking You.",
            requestPermissions: true,
            stale: false,
            distanceFilter: 50,
        };

        function handleLocationChange(newLocation, error) {
            if (error) {
                if (error.code === "NOT_AUTHORIZED") {
                    if (
                        window.confirm(
                            "This app needs your location, but does not have permission.\n\n" +
                            "Open settings now?"
                        )
                    ) {
                        BackgroundGeolocation.openSettings();
                    }
                }
                console.error(error);
                return;
            }

            setLocation(newLocation);
        }

        BackgroundGeolocation.addWatcher(watcherOptions, handleLocationChange)
            .then((watcherId) => {
                // When the component is unmounted, remove the watcher to save
                // resources.
                return () => {
                    BackgroundGeolocation.removeWatcher({ id: watcherId });
                };
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (location && location.latitude && location.longitude) {
            dispatch(updateLiveLocation(
                { latitude: location.latitude, longitude: location.longitude }
            ));
        }
    }, [location]);

    return (
        <div>
            {location ? (
                <div>
                    <p>Latitude: {location.latitude}</p>
                    <p>Longitude: {location.longitude}</p>
                    <p>Accuracy: {location.accuracy}</p>
                </div>
            ) : (
                <p>Fetching location...</p>
            )}

            {guessLocation()}
        </div>
    );
}

function guessLocation(callback, timeout) {
    let lastLocation;
    BackgroundGeolocation.addWatcher(
        {
            requestPermissions: false,
            stale: true,
        },
        (location) => {
            lastLocation = location || undefined;
        }
    ).then((id) => {
        setTimeout(() => {
            callback(lastLocation);
            BackgroundGeolocation.removeWatcher({ id });
        }, timeout);
    });
}


// export default BackgroundLocationService;

export { BackgroundLocationService, guessLocation };
