import React, { useEffect, useState } from "react";
import { IonCol, IonContent, IonItem, IonPage, IonRow, IonLabel, IonItemSliding, IonList, IonAvatar, IonSearchbar, IonCheckbox } from "@ionic/react";
import { useHistory } from "react-router";
import TopNavBar from "../../../components/navbars/topNavBar";
import { useDispatch, useSelector } from "react-redux";
import { selectLeadById, selectModificationStatus, selectRecentClickedLead, updateLead, updateSingleData } from "../reducerSlices/leadSlice";
import { createImageFromInitials, getRandomColor } from "../../../app/common/common";
import ShortText from "../../../components/controls/textboxes/shortText";
import { onValueChanged, sections } from "../services/leadSaver";
import { Operation } from "../../../app/common/constants";
import PhoneText from "../../../components/controls/textboxes/phoneText";
import NumberText from "../../../components/controls/textboxes/numberText";
import EmailText from "../../../components/controls/textboxes/emailText";
import { isFormValid } from "../../../components/controls/services/formService";
import SaveButton from "../../../components/buttons/saveButton";
import { fetchStatus, tenantId } from "../../../app/api/client";
import EllipsisLoader from "../../../components/loaders/ellipsisLoader";


function ContactDetails() {

    const history = useHistory();
    const dispatch = useDispatch();


    const lead = useSelector(selectLeadById);
    const recentClickedLead = useSelector(selectRecentClickedLead);

    const onBackButtonClicked = (e) => {
        onCancel();
        history.push('contact');
    };

    useEffect(() => {
        if (recentClickedLead === undefined) {
            history.push('lead');
        }
    }, [recentClickedLead]);

    const detail = lead && lead.Contacts[lead.Contacts.length - 1];

    const onValueUpdated = (e, section, value) => {
        let _leadObj = Object.assign({}, lead);
        let _newLeadObj = onValueChanged(_leadObj, e, section, value);
        dispatch(updateSingleData({
            lead: _newLeadObj,
            operation: Operation.UPDATED
        }));
    }

    const LEAD_FORM = "Leadform";

    const [lFInvalid, setLFInvalid] = useState(false);
    const [lfSubmitted, setLFSubmitted] = useState(false);
    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);

    const [googleAddressValidate, setGoogleAddressValidate] = useState(false);

    const customValidation = () => {
        if (lead && lead.GoogleAddressDetail && lead.GoogleAddressDetail.place_id) {
            if (lead.GoogleAddressDetail.place_id == "") {
                setGoogleAddressValidate(true);
                return false;
            } else {
                setGoogleAddressValidate(false);
                return true;
            }
        } else {
            setGoogleAddressValidate(true);
            return false;
        }
    }

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (customValidation() && isFormValid(LEAD_FORM)) {
            if (lead) {
                if (created) {
                    // dispatch(createLead({
                    //     lead: "test",
                    //     serviceType: "TENANTADMIN",
                    //     operation: "SAVELEAD",
                    //     payload: {
                    //         id: id,
                    //         data: JSON.stringify({
                    //             lead: JSON.stringify(lead),
                    //             photo: {
                    //                 contentType: "image/jpg",
                    //                 length: 0,
                    //                 // photoData: base64Image
                    //             }
                    //         })
                    //     }
                    // }));
                }
                else {
                    dispatch(updateLead({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVELEAD",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                lead: JSON.stringify(lead),
                                photo: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    // photoData: base64Image
                                }
                            })
                        }
                    }));
                }
            }
            setCreatemode(false);
            setEditmode(false);
            setLFInvalid(false);
            setLFSubmitted(false);
        }
        else {
            setLFInvalid(true);
        }
        setLFSubmitted(true);
        // setActiveAddBtn(true);
    };

    const onCancel = (e) => {
        setCreatemode(false);
        setEditmode(false);
        setLFInvalid(false);
        setLFSubmitted(false);
        if (lead) {
            // let _leadObj = Object.assign({}, lead);
            // _leadObj.Contacts.pop();

            const _leadObj = { ...lead }
            let id = lead && lead.Contacts[lead.Contacts.length - 1].id; // last ID
            _leadObj.Contacts = _leadObj.Contacts.filter(d => d.id !== id);

            dispatch(updateSingleData({
                lead: _leadObj,
                operation: Operation.UPDATED
            }));
        }
    };

    const leadModificationStatus = useSelector(selectModificationStatus);

    useEffect(() => {
        if (leadModificationStatus === fetchStatus.SUCCEEDED) {
            history.push('contact');
        }
    }, [leadModificationStatus]);


    return (
        <IonPage>
            <TopNavBar
                pageTitle="Contacts"
                onBackButtonClicked={(e) => onBackButtonClicked(e)} />
            <IonContent>
                <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol >
                        <IonLabel className="c-heading">{lead && lead.LeadName ? lead.LeadName.Data : ""}</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Full Name</IonLabel>
                            <ShortText
                                id={`txtFullName-${detail && detail.id}`}
                                name={`txtFullName-${detail && detail.id}`}
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={detail && detail.FullName ? detail.FullName.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                required={true}
                                errorMessage={"Enter full name"}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Position</IonLabel>
                            <ShortText
                                id={`drpPositions-${detail && detail.id}`}
                                name={`drpPositions-${detail && detail.id}`}
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={detail ? detail.Position.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                required={true}
                                errorMessage={"Enter position"}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Contact Number</IonLabel>
                            <PhoneText
                                id={`txtContactNumber-${detail && detail.id}`}
                                name={`txtContactNumber-${detail && detail.id}`}
                                PhoneTextClasses="form-control"
                                value={detail && detail.ContactNumber ? detail.ContactNumber.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                required={true}
                                errorMessage={"Enter contact number"}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Extension</IonLabel>
                            <NumberText
                                id={`txtExtension-${detail && detail.id}`}
                                name={`txtExtension-${detail && detail.id}`}
                                NumberTextClasses="form-control"
                                value={detail && detail.Extension ? detail.Extension.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                            // required={contactForm && contactForm.Extension.Required}
                            // errorMessage={contactForm && contactForm.Extension.Validation}
                            // form={props.form}
                            // submited={props.submitted}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Email</IonLabel>
                            <EmailText
                                id={`txtEmail-${detail && detail.id}`}
                                name={`txtEmail-${detail && detail.id}`}
                                EmailTextClasses="form-control"
                                placeholder="xyz@example.com"
                                value={detail && detail.Email ? detail.Email.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                required={true}
                                errorMessage={"Enter email-id"}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Client Interest</IonLabel>
                            <ShortText
                                id={`txtClientInterest-${detail && detail.id}`}
                                name={`txtClientInterest-${detail && detail.id}`}
                                ShortTextClasses="form-control lead-contact-cell-width-2"
                                placeholder=""
                                value={detail && detail.ClientInterest ? detail.ClientInterest.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                            // required={contactForm && contactForm.ClientInterest.Required}
                            // errorMessage={contactForm && contactForm.ClientInterest.Validation}
                            // form={props.form}
                            // submited={props.submitted}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Decision Maker </IonLabel>
                            <IonCheckbox
                                id={`ckbContactMaker-${detail && detail.id}`}
                                name={`ckbContactMaker-${detail && detail.id}`}
                                // checked={detail && detail.ContactMaker ? (detail.ContactMaker.Data === "Y") : false}
                                onIonChange={(e) => onValueUpdated(e, sections.CONTACT_DEATILS, e.detail.checked)}

                                labelPlacement="start">Decision Maker</IonCheckbox>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    {leadModificationStatus !== fetchStatus.LOADING ?
                        <IonCol size="11">
                            <SaveButton onSaveButtonClicked={(e) => onSaveClicked(e, lead ? lead.id : "", createMode)} />
                        </IonCol>
                        : <IonCol size="auto">
                            <EllipsisLoader />
                        </IonCol>
                    }
                </IonRow>

            </IonContent>
        </IonPage >
    )
}

export default ContactDetails;