import { unwrapResult } from "@reduxjs/toolkit";
import { USER_KEY, Role } from "../../../app/common/constants";
import store from '../../../app/store';
import { login, refreshToken } from '../reducerSlices/accountsSlice';

export const accountService = {
    isAuthenticated: () => {
        return localStorage.getItem(USER_KEY) ? true : false;
        //return true;
    },
    getAuthenticatedUser: () => {
        let user;
        if (accountService.isAuthenticated()) {
            user = JSON.parse(localStorage.getItem(USER_KEY));
        }
        return user || { id: "" };
    },
    login: async (loginModel) => {
        const result = await store.dispatch(login(loginModel));
        const data = unwrapResult(result);
        if (data && data.id) {
            // localStorage.setItem(USER_KEY, JSON.stringify(data));
            // const user = accountService.getAuthenticatedUser();
            //startRefreshTokenTimer(user);
        }
    },
    logout: () => {
        if (accountService.isAuthenticated()) {
            //stopRefreshTokenTimer();
            localStorage.removeItem(USER_KEY);
        }
    },
    getToken: () => {
        const user = accountService.getAuthenticatedUser();
        return user ? `Bearer fake-jwt-token ${user.jwtToken}` : "";
    },
    isAdmin: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.ADMIN ? true : false;
    },
    isPayroll: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.PAYROLL ? true : false;
    },
    isReviewer: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.REVIEWER ? true : false;
    },
    isApplicant: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.APPLICANT ? true : false;
    },
    isStephanie: () => {
        const user = accountService.getAuthenticatedUser();
        if (user) {
            switch (user.email) {
                case 'thk3355@gmail.com':
                    return true;
                    break;
                case 'marisol@spstaff.com':
                    return true;
                    break;
                case 'luis@spstaff.com':
                    return true;
                    break;
                case 'liana@spstaff.com':
                    return true;
                    break;
                case 'ana@spstaff.com':
                    return true;
                    break;
                case 'ana.g@spstaff.com':
                    return true;
                    break;
                case 'maria.h@spstaff.com':
                    return true;
                    break;
                case 'jennifer@spstaff.com':
                    return true;
                    break;
                case 'monica@sbaysafety.com':
                    return true;
                    break;
                case 'monica@spstaff.com':
                    return true;
                    break;
                case 'Trino@spstaff.com':
                    return true;
                    break;
                case 'donaji@spstaff.com':
                    return true;
                    break;
                case 'goreti@spstaff.com':
                    return true;
                    break;
                case 'ashley@spstaff.com':
                    return true;
                    break;
                case 'chrystal@spstaff.com':
                    return true;
                    break;
                case 'carlos@spstaff.com':
                    return true;
                    break;
                case 'monique@spstaff.com':
                    return true;
                    break;
                case 'manuel@spstaff.com':
                    return true;
                    break;
                case 'adriana@spstaff.com':
                    return true;
                    break;
                case 'sabrina@spstaff.com':
                    return true;
                    break;
                case 'lalo@spstaff.com':
                    return true;
                    break;
                case 'maria.h@spstaff.com':
                    return true;
                    break;
                case 'yanira@spstaff.com':
                    return true;
                    break;
                case 'ashley.t@spstaff.com':
                    return true;
                    break;
                case 'daniel.g@spstaff.com':
                    return true;
                    break;
                case 'stephanie@spstaff.com':
                    return true;
                    break;

                default:
                    // code block
            }

        }
        return false;
    },
    isSalesRep: () => {
        const user = accountService.getAuthenticatedUser();
        if (user) {
            switch (user.email) {
                case 'thk3355@gmail.com':
                    return true;
                    break;
                case 'marisol@spstaff.com':
                    return true;
                    break;
                case 'luis@spstaff.com':
                    return true;
                    break;
                case 'liana@spstaff.com':
                    return true;
                    break;
                case 'ana@spstaff.com':
                    return true;
                    break;
                case 'ana.g@spstaff.com':
                    return true;
                    break;
                case 'maria.h@spstaff.com':
                    return true;
                    break;
                case 'jennifer@spstaff.com':
                    return true;
                    break;
                case 'monica@sbaysafety.com':
                    return true;
                    break;
                case 'monica@spstaff.com':
                    return true;
                    break;
                case 'Trino@spstaff.com':
                    return true;
                    break;
                case 'donaji@spstaff.com':
                    return true;
                    break;
                case 'goreti@spstaff.com':
                    return true;
                    break;
                case 'ashley@spstaff.com':
                    return true;
                    break;
                case 'chrystal@spstaff.com':
                    return true;
                    break;
                case 'carlos@spstaff.com':
                    return true;
                    break;
                case 'monique@spstaff.com':
                    return true;
                    break;
                case 'manuel@spstaff.com':
                    return true;
                    break;
                case 'adriana@spstaff.com':
                    return true;
                    break;
                case 'lalo@spstaff.com':
                    return true;
                    break;
                default:
                // code block
            }

        }
        return user && user.role === Role.SALESREP ? true : false;
    },
    isSalesManager: () => {
        const user = accountService.getAuthenticatedUser();

        return user && user.role === Role.SALESMANAGER ? true : false;
    },
    isCRMAccess: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.crmAccess === "Yes" ? true : false;
    }
}

let refreshTokenTimeout;

const startRefreshTokenTimer = (user) => {
    if (user) {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(user.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        refreshTokenTimeout = setTimeout(accountService.refreshTokenAsync, timeout);
    }
}

const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout);
}
