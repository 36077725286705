import React, { useEffect, useState } from 'react';

// import './css/input.css';
// import './css/statement.css';

function Statement(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    return (
        <>
            <textarea
                id={props.id}
                name={props.name}
                value={props.value ? props.value : ""}
                className={`statement ${props.StatementClasses ? props.StatementClasses : ""} ${invalid && "invalid-input"}`}
                rows={props.rows ? props.rows : "4"}
                cols={props.cols ? props.value : "10"}
                onChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
                placeholder={`${props.placeholder ? props.placeholder : ""}`}>
            </textarea>
            {
                invalid && <span className="error-label">
                    <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </>
    );
}

export default Statement;