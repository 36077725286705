import { IonSelect, IonSelectOption } from '@ionic/react';
import React, { useEffect, useState } from 'react';

// import './css/selector.css';

function Selector(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (props.required) {
            if (edited || props.submited) {
                if (!value) {
                    setInvalid(true);
                }
                else {
                    setInvalid(false);
                }
            }
            else {
                setInvalid(false);
            }
            setEdited(false);
        }

    }, [value, props.required, edited, props.submited]);

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    return (
        <>
            {/* <select
                id={props.id}
                name={props.name}
                value={value}
                className={`${props.SelectorClasses ? props.SelectorClasses : ""} ${invalid && "invalid-input"}`}
                multiple={props.isMultiple ? "multiple" : ""}
                onChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}>
                {!props.withoutDefault && <option className={props.optionInitialClasses ? props.optionInitialClasses : ""} value="">{props.default ? props.default : " --- Select --- "}</option>}
                {props.options ? props.options : <></>}
            </select>
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            } */}

            <IonSelect
                id={props.id}
                name={props.name}
                value={value}
                className={`${props.SelectorClasses ? props.SelectorClasses : ""} ${invalid && "invalid-input"}`}
                // multiple={props.isMultiple ? "multiple" : ""}
                onIonChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
                placeholder={props.placeholder ? props.placeholder : ""}
            >
                {props.options ? props.options : <></>}
            </IonSelect>
        </>
    );
}

export default Selector;