import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

// import Collection from '../../../components/Controls/paginators/collection';
// import { refreshCreated } from '../../../components/Controls/paginators/services/createdResource';
// import { refreshRemoved } from '../../../components/Controls/paginators/services/removedResource';
// import { toaster } from '../../../components/controls/toasts/toaster'; 
// import { isJSON } from '../../../utilities/utilityFunctions';

import { BajoAPI, fetchStatus } from '../../../app/api/client';
import { getAxiosRequestConfig } from '../../../app/common/common';
import { isJSON } from '../../../app/utilities/utilityFunctions';
// import Collection from '../../../components/Controls/paginators/collection';
// import { refreshCreated } from '../../../components/Controls/paginators/services/createdResource';
// import { refreshRemoved } from '../../../components/Controls/paginators/services/removedResource';
import { toaster } from '../../../components/controls/toasts/toaster';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        // records: new Collection(),
        // firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    locationOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    locationOptionsAll: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    liveLocation: {
        data: undefined
    }
};




export const getLocationOptions = createAsyncThunk('locations/getLocationOptions', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getLocationOptionsAll = createAsyncThunk('locations/getLocationOptionsAll', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const saveUserLocation = createAsyncThunk('locations/saveUserLocation', async (locationModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, locationModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let location = undefined;
        if (data && isJSON(data)) {
            location = JSON.parse(location);
        }
        return {
            location: location,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const locationSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        // updateStatus: (state) => {
        //     state.data.status = fetchStatus.DONE;
        // },
        // updateIsFiltered: (state) => {
        //     state.data.isFiltered = true;
        // },
        refreshLocationOptions: (state) => {
            state.locationOptions.refreshed = true;
        },
        refreshLocationOptionsAll: (state) => {
            state.locationOptionsAll.refreshed = true;
        },
        updateLiveLocation: (state, _data) => {
            state.liveLocation.data =  Object.assign({}, _data.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLocationOptions.pending, (state, action) => {
            state.locationOptions.status = fetchStatus.LOADING;
        }).addCase(getLocationOptions.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['LocationName'];
                delete option['LocationName'];
                return option;
            });
            state.locationOptions.options = _options;
            state.locationOptions.status = fetchStatus.SUCCEEDED;
            state.locationOptions.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getLocationOptions.rejected, (state, action) => {
            state.locationOptions.status = fetchStatus.FAILED;
            state.locationOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLocationOptionsAll.pending, (state, action) => {
            state.locationOptions.status = fetchStatus.LOADING;
        }).addCase(getLocationOptionsAll.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['LocationName'];
                delete option['LocationName'];
                return option;
            });
            state.locationOptions.options = _options;
            state.locationOptions.status = fetchStatus.SUCCEEDED;
            state.locationOptions.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getLocationOptionsAll.rejected, (state, action) => {
            state.locationOptions.status = fetchStatus.FAILED;
            state.locationOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { refreshLocationOptions, refreshLocationOptionsAll, updateLiveLocation } = locationSlice.actions;

export default locationSlice.reducer;

export const selectAllLocations = state => state.locations.data.records;

export const selectTotalItems = state => state.locations.data.totalItems;

export const selectStatus = state => state.locations.data.status;

export const selectError = state => state.data.locations.error;
export const selectDispatchDetail = (state) => {
    return state.locations.dispatchDetail ? state.locations.dispatchDetail.data : undefined;
}

export const selectLocationById = (state) => {
    return state.locations.single ? state.locations.single.data : undefined;
}

export const selectCurrentPage = state => state.locations.data.currentPage;

export const selectFirstRecord = (state, currentPage) => {
    return state.locations.data.firstRecords.Get(currentPage);
}

export const selectLocationOptions = state => state.locations.locationOptions.options;

export const selectLocationOptionsStatus = state => state.locations.locationOptions.status;

export const selectLocationOptionsRefreshed = state => state.locations.locationOptions.refreshed;

export const selectLocationOptionsAll = state => state.locations.locationOptionsAll.options;
export const selectLocationOptionsAllStatus = state => state.locations.locationOptionsAll.status;

export const selectLocationOptionsAllRefreshed = state => state.locations.locationOptionsAll.refreshed;

export const selectLiveLocation = state => state.locations.liveLocation.data;