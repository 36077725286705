import React from 'react';
import { Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import './custom.css';
import { IonReactRouter } from '@ionic/react-router';
import { square, home, search } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/accounts/screens/login';
import Test from './pages/test/screens/test';
import Lead from './pages/lead/screens/lead';
import LeadDetails from './pages/lead/screens/leadDetails';
import Appointment from './pages/appointment/screens/appointment';
import AppointmentDetails from './pages/appointment/screens/appointmentDetails';
import Notification from './pages/notification/screens/notification';
import PrivateRoute from './app/routes/privateRoute';
import ContactDetails from './pages/lead/screens/contactDetails';
import Contacts from './pages/lead/screens/contacts';
import Menu from './components/menu/Menu';
import LeadSearch from './pages/lead/screens/leadSearch';
import RouteList from './pages/appointment/screens/routeList';

setupIonicReact();


const App: React.FC = () => (
  <IonApp>
    <Menu />
    <IonReactRouter>
        <IonRouterOutlet id="main" animated={false} mode="ios" >
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="" component={Appointment} />
          <PrivateRoute exact path="/appointment" component={Appointment} />
          <PrivateRoute exact path="/appointmentDetails" component={AppointmentDetails} />
          <PrivateRoute exact path="/lead" component={Lead} />
          <PrivateRoute exact path="/leadDetails" component={LeadDetails} />
          <PrivateRoute exact path="/contact" component={Contacts} />
          <PrivateRoute exact path="/contactDetails" component={ContactDetails} />
          <PrivateRoute exact path="/leadSearch" component={LeadSearch} />
          <PrivateRoute exact path="/test" component={Test} />
          <PrivateRoute exact path="/notification" component={Notification} />
          <PrivateRoute exact path="/routeList" component={RouteList} />
        </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
