import React, { useEffect, useState } from "react";
import { IonCol, IonContent, IonItem, IonPage, IonRow, IonLabel, IonItemSliding, IonList, IonAvatar, IonSearchbar, IonButton, IonIcon } from "@ionic/react";
import { useHistory } from "react-router";
import TopNavBar from "../../../components/navbars/topNavBar";
import { useDispatch, useSelector } from "react-redux";
import { createContactDetail, selectLeadById, selectRecentClickedLead } from "../reducerSlices/leadSlice";
import { createImageFromInitials, getRandomColor } from "../../../app/common/common";
import { addOutline, personAddOutline, star } from "ionicons/icons";


function Contacts() {

    const history = useHistory();
    const dispatch = useDispatch();

    const lead = useSelector(selectLeadById);
    const recentClickedLead = useSelector(selectRecentClickedLead);

    const onBackButtonClicked = (e) => {
        history.push('leaddetails');
    };

    useEffect(() => {
        if (recentClickedLead === undefined) {
            history.push('lead');
        }
    }, [recentClickedLead]);

    const [searchQuery, setSearchQuery] = useState('');

    const filteredContacts = lead && lead.Contacts && lead.Contacts.filter(contact =>
        contact.FullName.Data.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // const [filteredContacts, setFilteredContacts] = useState();


    // useEffect(()=>{
    //     setFilteredContacts(lead && lead.Contacts && lead.Contacts.filter(contact =>
    //         contact.FullName.Data.toLowerCase().includes(searchQuery.toLowerCase())
    //     ));
    // },[lead && lead.Contacts,searchQuery]);

    const onAddRecordClicked = () => {
        dispatch(createContactDetail());
        history.push('contactDetails');
    }

    return (
        <IonPage>
            <TopNavBar
                pageTitle="Contacts"
                onBackButtonClicked={(e) => onBackButtonClicked(e)} />
            <IonContent>
                {/* <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top  ">
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="">{lead && lead.LeadName ? lead.LeadName.Data : ""}</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol size="auto">
                        <IonButton
                            className="c-add-btn"
                            fill="clear"
                            onClick={(e) => onAddRecordClicked(e)}>
                            <IonIcon slot="icon-only" className="c-pe-2" icon={personAddOutline}></IonIcon> New Contact
                        </IonButton>
                    </IonCol>
                </IonRow> */}

                <IonRow className="ion-justify-content-between ion-align-items-center c-m-0 ion-margin-top ion-margin-end">
                    <IonCol size="6">
                        <IonItem lines="none">
                            <IonLabel position="">{lead && lead.LeadName ? lead.LeadName.Data : ""}</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol size="auto">
                        <IonButton
                            className="c-add-btn"
                            fill="clear"
                            onClick={(e) => onAddRecordClicked(e)}>
                            <IonIcon slot="icon-only" className="c-pe-2" icon={personAddOutline}></IonIcon> New Contact
                        </IonButton>
                    </IonCol>
                </IonRow>


                <IonRow className="ion-justify-content-between ion-margin">
                    <IonCol size="12">
                        <IonSearchbar className="c-ion-searchbar" value={searchQuery} onIonChange={e => setSearchQuery(e.detail.value)}></IonSearchbar>
                    </IonCol>
                </IonRow>

                <IonList class="ion-margin-end c-pe-2">
                    {/* {lead && lead.Contacts && lead.Contacts.map(d => ( */}


                    {/* {filteredContacts && filteredContacts.map(d => (
                         */}
                    {filteredContacts && filteredContacts
                        .sort((a, b) => a.FullName.Data.localeCompare(b.FullName.Data))
                        .map(d => (
                            <IonItemSliding key={d.id}>
                                <IonItem detail={false} href={`tel:${d.ContactNumber.Data}`}>
                                    <IonAvatar slot="start">
                                        <img src={createImageFromInitials(50, d.FullName.Data, getRandomColor())} alt={d.FullName.Data} title={d.FullName.Data} />
                                        {/* <img src={'https://randomuser.me/api/portraits/men/1.jpg'} alt={d.FullName.Data} /> */}
                                    </IonAvatar>
                                    <IonLabel>
                                        <h2>{d.FullName.Data}</h2>
                                        <p>{d.ContactNumber.Data}</p>
                                        <p>{d.ClientInterest && d.ClientInterest.Data}</p>
                                    </IonLabel>
                                    {d.ContactMaker && d.ContactMaker.Data === "Y" ? <IonIcon icon={star} color="warning"></IonIcon> : <></>}
                                </IonItem>
                            </IonItemSliding>
                        ))}
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default Contacts;