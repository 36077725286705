import React, { useEffect, useState } from "react";
import { IonButton, IonCol, IonContent, IonHeader, IonButtons, IonInput, IonItem, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonText, IonIcon, IonLabel, IonImg, IonToast, IonModal } from "@ionic/react";
import { addOutline, calendarClearOutline, callOutline, camera, cameraOutline, cardOutline, checkmarkOutline, closeOutline, navigate, notifications, peopleOutline, qrCodeOutline, timeOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import BackButton from "../../../components/buttons/backButton";
import NotificationButton from "../../../components/buttons/notificationButton";
import SaveButton from "../../../components/buttons/saveButton";
import TopNavBar from "../../../components/navbars/topNavBar";

import businessCardImg from '../../../assets/business-card.jpg'
import { useDispatch, useSelector } from "react-redux";
import { createLead, getAppointmentsById, getLeadById, getLeadForm, selectLeadById, selectLeadForm, selectLeadFormStatus, selectRecentClickedLead, selectSingleStatus, updateLead, updateSingleData, selectCreationStatus, selectModificationStatus, getLeadByAddress, selectLeadByAddress, createNewAppointment, updateRecentClickedAppointment, getBusinessCardDetailsByPhoto, selectBusinessCardDetailsByPhoto, selectCreationError, selectModificationError, selectSingleLeadSearchData, selectBusinessCardDetailsByPhotoState } from "../reducerSlices/leadSlice";
import { fetchStatus, tenantId } from "../../../app/api/client";
import { onGoogleDetails, onValueChanged, onValueChangedBusinessCardDetailsByPhoto, sections } from "../services/leadSaver";
import { Operation } from "../../../app/common/constants";
import ShortText from "../../../components/controls/textboxes/shortText";
import PhoneText from "../../../components/controls/textboxes/phoneText";
import { isFormValid } from "../../../components/controls/services/formService";
import Selector from "../../../components/controls/selectors/selector";
import { accountService } from "../../accounts/services/accountService";
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from "../../locations/reducerSlices/locationSlice";
import { isObjectArray } from "../../../app/utilities/utilityFunctions";
import { toLocalDate } from "../../../app/common/common";

import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import AddressParser from "parse-google-address";
import 'setimmediate';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useCamera } from '@ionic/react-hooks/camera';
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import GridLoader from "../../../components/loaders/gridLoader";
import EllipsisLoader from "../../../components/loaders/ellipsisLoader";

function LeadDetails() {

    const history = useHistory();
    const dispatch = useDispatch();

    const LEAD_FORM = "Leadform";

    const [pageLoaded, setPageLoaded] = useState(false);
    const lead = useSelector(selectLeadById);
    const recentClickedLead = useSelector(selectRecentClickedLead);

    const leadRequestStatus = useSelector(selectSingleStatus);

    const [lFInvalid, setLFInvalid] = useState(false);
    const [lfSubmitted, setLFSubmitted] = useState(false);
    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);

    const leadFormStatus = useSelector(selectLeadFormStatus);
    const leadForm = useSelector(selectLeadForm);

    const [googleAddressValidate, setGoogleAddressValidate] = useState(false);
    const [googleAddressVerify, setGoogleAddressVerify] = useState(true);

    const [activeAddBtn, setActiveAddBtn] = useState(true);

    const user = accountService.getAuthenticatedUser();

    const duplicateLeadAddress = useSelector(selectLeadByAddress);

    const businessCardDetailsByPhoto = useSelector(selectBusinessCardDetailsByPhoto);
    const businessCardDetailsByPhotoState = useSelector(selectBusinessCardDetailsByPhotoState);

    const [autocompleteValue, setAutocompleteValue] = useState("");

    const creationError = useSelector(selectCreationError);
    const modificationError = useSelector(selectModificationError);

    const singleLeadSearchData = useSelector(selectSingleLeadSearchData);

    const [isOpen, setIsOpen] = useState(false);

    const onBackButtonClicked = (e) => {
        setLFSubmitted(false);
        setActiveAddBtn(false);
        setAutocompleteValue("");
        photoReset();
        history.push('lead');
    };

    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true);
            if (leadFormStatus === fetchStatus.IDLE) {
                _loadForm();
            }
        }
    });

    const _loadForm = async () => {
        await dispatch(getLeadForm({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "GETFORMBYTYPE",
            payload: {
                id: "",
                data: "LeadForm"
            }
        }));
    }

    const locationOptions = useSelector(selectLocationOptions);
    const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
    const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);



    useEffect(() => {
        if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
            dispatch(getLocationOptions({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLOCATIONOPTIONS",
                payload: {
                    id: "",
                    data: JSON.stringify({})
                }
            }));
        }
    }, [locationOptionsStatus, locationOptionsRefreshed]);

    let _locationOptions = undefined;

    if (isObjectArray(locationOptions)) {
        _locationOptions = locationOptions.map((o) => (
            <IonSelectOption value={o.value} key={o.value} >{o.text}</IonSelectOption>
        ));
    }

    const _loadLead = async (id, page) => {
        await dispatch(getLeadById({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLEADBYID",
                payload: {
                    id: id,
                    data: ""
                }
            }
        }));

        await dispatch(getAppointmentsById({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETAPPOINTMENTSBYLEADID",
                payload: {
                    id: id,
                    data: ""
                }
            }
        }));
    }


    const [leadLoaded, setLeadLoaed] = useState(false);
    useEffect(() => {
        if (leadRequestStatus === fetchStatus.IDLE || leadLoaded === false) {
            if (recentClickedLead && recentClickedLead !== 0) {
                _loadLead(recentClickedLead, '')
            }
        }
        setLeadLoaed(true);
    }, [leadRequestStatus]);

    useEffect(() => {
        if (recentClickedLead === undefined) {
            photoReset();
            history.push('lead');
        }

        if (recentClickedLead === 0) {
            setCreatemode(true);
        } else {
            setCreatemode(false);
        }

    }, [recentClickedLead]);

    useEffect(() => {
        if (singleLeadSearchData && singleLeadSearchData.googleData) {
            if (singleLeadSearchData.googleData.vicinity) {
                let address = singleLeadSearchData.googleData.name + ' ' + singleLeadSearchData.googleData.vicinity;
                setAutocompleteValue(address);
            }
        }
    }, [singleLeadSearchData])

    useEffect(() => {
        if (recentClickedLead) {
            _loadLead(recentClickedLead);
        }
    }, [recentClickedLead]);

    const onValueUpdated = (e, section, value) => {
        let _leadObj = Object.assign({}, lead);
        let _newLeadObj = onValueChanged(_leadObj, e, section, value);
        dispatch(updateSingleData({
            lead: _newLeadObj,
            operation: Operation.UPDATED
        }));
    };

    const _getDuplicateLead = (place_id) => {
        dispatch(getLeadByAddress({
            // page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLEADBYADDRESS",
                payload: {
                    id: place_id,
                    // data: place_id
                }
            }
        }));
    }

    const onGoogleAddressUpdated = (value) => {
        setGoogleAddressValidate(false);
        setGoogleAddressVerify(true);
        _getDuplicateLead(value.place_id);
        AddressParser.Parse_Reverse_GeoCode_Address(value.address_components, function (err, ParsedData) {
            if (err) console.error('err ~~~~~~~>', err);

            let drpLeadType = document.getElementById("drpLeadType").value;

            let _leadObj = Object.assign({}, lead);

            _leadObj.Location = {
                "Id": "drpLocations",
                "Data": document.getElementById("drpLocations").value
            }
            _leadObj.LeadType = {
                "Id": "drpLeadType",
                "Data": document.getElementById("drpLeadType").value
            }
            _leadObj.LeadName = {
                "Id": "txtLeadName",
                "Data": document.getElementById("txtLeadName").value
            }
            _leadObj.LeadPhone = {
                "Id": "txtLeadPhone",
                "Data": document.getElementById("txtLeadPhone").value
            }
            _leadObj.Supervisor = {
                "Id": "txtSupervisor",
                "Data": document.getElementById("txtSupervisor").value
            }
            _leadObj.LeadTelePhone = {
                "Id": "txtLeadTelePhone",
                "Data": document.getElementById("txtLeadTelePhone").value
            }
            _leadObj.Position = {
                "Id": "drpPosition",
                "Data": document.getElementById("drpPosition").value
            }
            _leadObj.AssignedTo = {
                "Id": "txtAssignedTo",
                "Data": ""
            }
            _leadObj.Status = {
                "Id": "drpStatuses",
                "Data": "New"
            }

            let _newLeadObj = onGoogleDetails(_leadObj, value, ParsedData);

            dispatch(updateSingleData({
                lead: _newLeadObj,
                operation: Operation.UPDATED
            }));
        });
    };

    const onAutocompleteChange = (event) => {
        setAutocompleteValue(event.target.value);
    };

    const customValidation = () => {
        if (lead && lead.GoogleAddressDetail && lead.GoogleAddressDetail.place_id) {
            if (lead.GoogleAddressDetail.place_id == "") {
                setGoogleAddressValidate(true);
                return false;
            } else {
                setGoogleAddressValidate(false);
                return true;
            }
        } else {
            setGoogleAddressValidate(true);
            return false;
        }
    }

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (customValidation() && isFormValid(LEAD_FORM) && googleAddressVerify) {
            if (lead) {
                let businessBase64Image = base64Image.split(',').pop();
                if (created) {
                    dispatch(createLead({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVELEAD",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                lead: JSON.stringify(lead),
                                businessCardPhoto: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    photoData: businessBase64Image
                                },
                                officePhoto: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    photoData: base64ImageOffice
                                }
                            })
                        }
                    }));
                }
                else {
                    dispatch(updateLead({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVELEAD",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                lead: JSON.stringify(lead),
                                businessCardPhoto: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    photoData: businessBase64Image
                                },
                                officePhoto: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    photoData: base64ImageOffice
                                }
                            })
                        }
                    }));
                }
            }
            // setCreatemode(false);
            // setEditmode(false);
            setLFInvalid(false);
            setLFSubmitted(false);
        }
        else {
            setLFInvalid(true);
        }
        setLFSubmitted(true);
        setActiveAddBtn(true);
    };


    const leadCreationStatus = useSelector(selectCreationStatus);
    const leadModificationStatus = useSelector(selectModificationStatus);

    const [showSubmitToast, setShowSubmitToast] = useState(false);
    const [showSubmitToastError, setShowSubmitToastError] = useState(false);


    const onToastDismissed = () => {
        // setShowSubmitToast(false);
    };

    useEffect(() => {
        if (leadModificationStatus === fetchStatus.FAILED) {
            setEditmode(true);
            setShowSubmitToastError(true);
        }

        if (leadModificationStatus === fetchStatus.SUCCEEDED) {
            setShowSubmitToast(true);
            window.location.reload();
            history.push('/lead');
        }

        if (leadModificationStatus === fetchStatus.LOADING) {
            setShowSubmitToastError(false)
        }
    }, [leadModificationStatus]);

    useEffect(() => {
        if (leadCreationStatus === fetchStatus.FAILED) {
            setEditmode(true);
            setShowSubmitToastError(true);
        }

        if (leadCreationStatus === fetchStatus.SUCCEEDED) {
            setShowSubmitToast(true);
            window.location.reload();
            history.push('/lead');
        }

        if (leadCreationStatus === fetchStatus.LOADING) {
            setShowSubmitToastError(false)
        }
    }, [leadCreationStatus]);

    const onAddRecordClicked = (e) => {
        dispatch(createNewAppointment());

        let data = {
            id: 0,
            leadId: lead ? lead.id : "",
            leadName: lead && lead.LeadName ? lead.LeadName.Data : "",
            locationId: lead && lead.Location ? lead.Location.Data : "",

        }
        console.log('onAddRecordClicked data ', data)
        dispatch(updateRecentClickedAppointment(data));
        photoReset();
        history.push("/appointmentDetails");
    }

    const onContactsClicked = (e) => {
        photoReset();
        history.push("/contact");
    }

    const onDirectionClicked = (e, placeId) => {
        e.preventDefault()
        if (placeId != '') {
            window.location.href = "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" + placeId;
        }
    }


    // ---------- BusinessCard ---------------
    const [base64Image, setbase64Image] = useState("");

    // const takePhoto = async () => {
    //     const photo = await Camera.getPhoto({
    //         resultType: CameraResultType.Base64,
    //         quality: 90,
    //         allowEditing: false,
    //     });

    //     console.log('photo --> ', photo)

    //     setbase64Image(photo.base64String);
    // };

    const { getPhoto } = useCamera();
    const [photoData, setPhotoData] = useState("");
    const [photoURL, setPhotoURL] = useState("");

    const takePhoto = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 20
        });

        const fileName = new Date().getTime() + '.jpeg';
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhoto = savedFileImage;
        setbase64Image(newPhoto.data);
        setPhotoURL(newPhoto.webviewPath);
    };

    const savePicture = async (photo, fileName) => {
        let base64Data = await base64FromPath(photo.webPath);
        return {
            filepath: fileName,
            webviewPath: photo.webPath,
            data: base64Data
        };
    };


    const _getBusinessCardDetails = () => {
        if (base64Image) {
            dispatch(getBusinessCardDetailsByPhoto({
                // page: page,
                model: {
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "GETBUSINESSCARDDETAILS",
                    payload: {
                        id: '',
                        data: JSON.stringify({
                            photo: {
                                contentType: "image/jpg",
                                length: 0,
                                photoData: base64Image
                            }
                        })
                    }
                }
            }));
        }
    }

    useEffect(() => {
        if (createMode) {
            _getBusinessCardDetails();
        }
    }, [base64Image])

    useEffect(() => {
        if (businessCardDetailsByPhoto) {
            // console.log('.....businessCardDetailsByPhoto', businessCardDetailsByPhoto);
            openModal();
        }
    }, [businessCardDetailsByPhoto]);


    // ---------- BusinessCard End ---------------

    // --------- Modal Copy Details ---------
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const copyInfoFromBusinessCard = (e) => {
        if (businessCardDetailsByPhoto) {
            let _leadObj = Object.assign({}, lead);
            let _newLeadObj = onValueChangedBusinessCardDetailsByPhoto(_leadObj, businessCardDetailsByPhoto);
            dispatch(updateSingleData({
                lead: _newLeadObj,
                operation: Operation.UPDATED
            }));


            setAutocompleteValue(businessCardDetailsByPhoto.address);
            setGoogleAddressValidate(true);
            setGoogleAddressVerify(false);
        }
        closeModal();
    }
    // --------- End Modal Copy Details ---------

    // --------- Office Photo ---------
    const [base64ImageOffice, setbase64ImageOffice] = useState("");
    const [imageOfficePhotoURL, setImageOfficePhotoURL] = useState("");

    const takeOfficePhoto = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 20
        });

        const fileName = new Date().getTime() + '.jpg';
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhoto = savedFileImage;

        // setbase64ImageOffice(newPhoto.data);
        let result = newPhoto.data.split(',').pop();
        setbase64ImageOffice(result);

        console.log('newPhoto', newPhoto)
        setImageOfficePhotoURL(newPhoto.webviewPath);
    };

    const photoReset = () => {
        setbase64ImageOffice("");
        setImageOfficePhotoURL("");
        setPhotoURL("");
        setbase64Image("");
    }

    return (
        <IonPage>
            {leadRequestStatus === fetchStatus.LOADING ||
                businessCardDetailsByPhotoState === fetchStatus.LOADING ? <div className="process-loader">
                <GridLoader />
            </div> : <></>}
            <TopNavBar
                pageTitle="Leads Details"
                onBackButtonClicked={(e) => onBackButtonClicked(e)} />
            <IonContent>
                <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol size="4">
                        <IonLabel className="c-heading">Action</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-end ion-margin-top ion-margin-start ion-margin-end">
                    <div className="container-action">
                        {lead && lead.GoogleAddressDetail && lead.GoogleAddressDetail.place_id &&
                            <IonButton
                                className="c-btn-new-appointment ion-margin-end"
                                fill="clear"
                                onClick={(e) => onDirectionClicked(e, lead.GoogleAddressDetail.place_id)}>
                                <IonIcon slot="icon-only" icon={navigate} className="c-pe-2" /> Navigate
                            </IonButton>
                        }
                        {lead && lead.id &&
                            <>
                                <IonButton
                                    className="c-contacts-btn ion-margin-end"
                                    fill="clear"
                                    onClick={(e) => onContactsClicked(e)}>
                                    <IonIcon slot="icon-only" icon={timeOutline} className="c-contacts-btn-icon c-pe-2" /> Contacts
                                </IonButton>

                                <IonButton
                                    className="c-btn-new-appointment ion-margin-end"
                                    fill="clear"
                                    onClick={(e) => onAddRecordClicked(e)}>
                                    <IonIcon slot="icon-only" icon={calendarClearOutline} className="c-pe-2" /> New Appointment
                                </IonButton>
                            </>
                        }
                        {(createMode || !(lead && lead.Documents && lead.Documents.some(d => d.DocumentType === 27))) &&
                            <IonButton
                                className="c-btn-business-card"
                                fill="clear"
                                onClick={(e) => takePhoto(e)}>
                                <IonIcon slot="icon-only" icon={cardOutline} className="c-pe-2"></IonIcon> Scan Business Card
                            </IonButton>
                        }
                        <IonButton
                            className="c-btn-business-card"
                            fill="clear"
                            onClick={(e) => takeOfficePhoto(e)}>
                            <IonIcon slot="icon-only" icon={cameraOutline} className="c-pe-2"></IonIcon> Office Photo
                        </IonButton>
                    </div>
                </IonRow>

                <IonRow className="ion-justify-content-center">
                    {photoURL &&
                        <IonCol size="6">
                            <div className="place-photo-container">
                                <IonImg className="place-photo-container-photo" src={photoURL} />
                            </div>
                        </IonCol>
                    }
                    {imageOfficePhotoURL &&
                        <IonCol size="6">
                            <div className="place-photo-container">
                                <IonImg className="place-photo-container-photo" src={imageOfficePhotoURL} />
                            </div>
                        </IonCol>
                    }
                </IonRow>


                <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol size="12">
                        <IonLabel className="c-heading">Pipeline Details</IonLabel>
                    </IonCol>
                </IonRow>

                {createMode &&
                    <IonRow>
                        <IonCol>
                            <IonItem lines="none">
                                <IonLabel position="stacked">Address</IonLabel>
                                <Autocomplete
                                    apiKey={'AIzaSyAakzSaZd7t-EBQkMQOu5U3QzhHV4yr1CY'}
                                    className="form-control"
                                    onPlaceSelected={(place, inputRef, autocomplete, e) => {
                                        onGoogleAddressUpdated(place);
                                    }
                                    }
                                    options={{
                                        types: [],
                                    }}
                                    id="txtAddress2"
                                    name="txtAddress2"
                                    form={LEAD_FORM}
                                    value={autocompleteValue ? autocompleteValue : ""}
                                    onChange={onAutocompleteChange}
                                />
                                {googleAddressValidate &&
                                    <span className="error-label">
                                        <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                        {'This field is required'}
                                    </span>
                                }
                                {!googleAddressVerify &&
                                    <span className="error-label">
                                        <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                        {'Please verify google address'}
                                    </span>
                                }
                                {duplicateLeadAddress &&
                                    <span className="error-label">
                                        <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                        {duplicateLeadAddress}
                                    </span>
                                }
                            </IonItem>
                        </IonCol>
                    </IonRow>
                }

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Street Address</IonLabel>
                            <ShortText
                                placeholder=""
                                ShortTextClasses="form-control"
                                value={lead && lead.Address ? lead.Address.Data : ""}
                                disabled={true}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">City</IonLabel>
                            <ShortText
                                placeholder=""
                                ShortTextClasses="form-control"
                                value={lead && lead.City ? lead.City.Data : ""}
                                disabled={true}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">State</IonLabel>
                            <ShortText
                                placeholder=""
                                ShortTextClasses="form-control"
                                value={lead && lead.State ? lead.State.Data : ""}
                                disabled={true}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Zip Code</IonLabel>
                            <ShortText
                                placeholder=""
                                ShortTextClasses="form-control"
                                value={lead && lead.ZipCode ? lead.ZipCode.Data : ""}
                                disabled={true}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Company Name</IonLabel>
                            <ShortText
                                id="txtLeadName"
                                name="txtLeadName"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.LeadName ? lead.LeadName.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm && leadForm.LeadName.Required}
                                errorMessage={leadForm && leadForm.LeadName.Validation}
                                disabled={!createMode}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Company Phone Number</IonLabel>
                            <PhoneText
                                id="txtLeadPhone"
                                name="txtLeadPhone"
                                PhoneTextClasses="form-control"
                                value={lead ? lead.LeadPhone.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm && leadForm.LeadPhone.Required}
                                errorMessage={leadForm && leadForm.LeadPhone.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                {/* 
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="stacked">City</IonLabel>
                            <IonLabel>{lead && lead.City ? lead.City.Data : ""}</IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonList>
                            <IonItem>
                                <IonLabel position="stacked">State</IonLabel>
                                <IonLabel>{lead && lead.State ? lead.State.Data : ""}</IonLabel>
                            </IonItem>
                        </IonList>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="stacked">Zip Code</IonLabel>
                            <IonLabel>{lead && lead.ZipCode ? lead.ZipCode.Data : ""}</IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow> */}

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Contact Name</IonLabel>
                            <ShortText
                                id="txtSupervisor"
                                name="txtSupervisor"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.Supervisor && lead.Supervisor.Data ? lead.Supervisor.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm && leadForm.Supervisor.Required}
                                errorMessage={leadForm && leadForm.Supervisor.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Contact Number</IonLabel>
                            <PhoneText
                                id="txtLeadTelePhone"
                                name="txtLeadTelePhone"
                                PhoneTextClasses="form-control"
                                value={lead && lead.LeadTelePhone ? lead.LeadTelePhone.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm && leadForm.LeadTelePhone.Required}
                                errorMessage={leadForm && leadForm.LeadTelePhone.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked">Contact Position</IonLabel>
                            <ShortText
                                id="drpPosition"
                                name="drpPosition"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.Position && lead.Position.Data ? lead.Position.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm && leadForm.Position.Required}
                                errorMessage={leadForm && leadForm.Position.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol size="6">
                        <IonList>
                            <IonItem lines="none">
                                <IonLabel position="stacked">Lead Type</IonLabel>
                                <Selector
                                    id="drpLeadType"
                                    name="drpLeadType"
                                    SelectorClasses="form-control"
                                    value={lead && lead.LeadType && lead.LeadType.Data ? lead.LeadType.Data : ""}
                                    // required={leadForm.LeadType.Required}
                                    // errorMessage={leadForm.LeadType.Validation}
                                    form={LEAD_FORM}
                                    submited={lfSubmitted}
                                    options={
                                        <>
                                            <IonSelectOption value="Cold">Cold</IonSelectOption>
                                            <IonSelectOption value="Warm">Warm</IonSelectOption>
                                            <IonSelectOption value="Hot">Hot</IonSelectOption>
                                        </>
                                    } onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                />
                            </IonItem>
                        </IonList>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <IonList>
                            <IonItem lines="none">
                                <IonLabel position="stacked">Location</IonLabel>
                                <Selector
                                    id="drpLocations"
                                    name="drpLocations"
                                    SelectorClasses="form-control"
                                    // value={lead ? lead.Location.Data : ""}
                                    value={createMode ? (user ? user.locationId : "") : lead ? lead.Location.Data : ""}
                                    // value={editMode ? (lead ? lead.Location.Data : "") : (user ? user.locationId : "")}
                                    onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                    options={
                                        <>
                                            {_locationOptions}
                                        </>
                                    }
                                    //disabled={!isAdmin}
                                    required={leadForm && leadForm.Location.Required ? leadForm.Location : true}
                                    errorMessage={leadForm && leadForm.Location.Validation}
                                    form={LEAD_FORM}
                                    submited={lfSubmitted} />
                            </IonItem>
                        </IonList>
                    </IonCol>
                </IonRow>

                <IonRow className="ion-justify-content-center">
                    {leadCreationStatus !== fetchStatus.LOADING && leadModificationStatus !== fetchStatus.LOADING ?
                        <IonCol size="11">
                            <SaveButton onSaveButtonClicked={(e) => onSaveClicked(e, lead ? lead.id : "", createMode)} />
                        </IonCol>
                        : <IonCol size="auto">
                            <EllipsisLoader />
                        </IonCol>
                    }
                </IonRow>

                <IonToast
                    position="top"
                    header="Submitted"
                    color="success"
                    isOpen={showSubmitToast}
                    onDidDismiss={() => onToastDismissed()}
                    message="Saved successfully"
                    duration={2000}
                />

                <IonToast
                    position="top"
                    header="Submitted"
                    color="danger"
                    isOpen={showSubmitToastError}
                    onDidDismiss={() => onToastDismissed()}
                    message={createMode ? creationError : modificationError}
                    duration={2000}
                />

                <IonToast
                    position="top"
                    header="Verification"
                    color="warning"
                    isOpen={!googleAddressVerify}
                    onDidDismiss={() => onToastDismissed()}
                    message="Please verify google address"
                    duration={2000}
                />

                <IonModal className="example-modal business-card-modal" isOpen={isOpen} onDidDismiss={closeModal}>
                    <IonContent>
                        <IonToolbar>
                            <IonTitle>Copy business card details</IonTitle>
                            <IonButtons slot="end">
                                <IonButton color="light" onClick={closeModal}>
                                    Close
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>

                        <IonRow className="ion-justify-content-center">
                            <IonCol size="11">
                                <h4>Would you like to copy the data from the business card?</h4>
                            </IonCol>
                        </IonRow>

                        <IonRow className="ion-justify-content-center">
                            <IonCol size="5">
                                <IonButton
                                    className="c-btn-yes"
                                    fill="clear"
                                    expand="block"
                                    onClick={(e) => copyInfoFromBusinessCard(e)}
                                >
                                    <IonIcon slot="icon-only" icon={checkmarkOutline} className="c-pe-2"></IonIcon> Yes
                                </IonButton>
                            </IonCol>
                            <IonCol size="5">
                                <IonButton
                                    className="c-btn-no"
                                    fill="clear"
                                    expand="block"
                                    onClick={closeModal}
                                >
                                    <IonIcon slot="icon-only" icon={closeOutline} className="c-pe-2"></IonIcon> No
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonContent>
                </IonModal>

            </IonContent>
        </IonPage>
    )
}

export default LeadDetails;