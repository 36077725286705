export const sections = {
    LEAD_DETAILS: 0,
    CONTACT_DEATILS: 1,
};

export const onValueChanged = (lead, e, section, value) => {
    let _lead = JSON.parse(JSON.stringify(lead));
    if (section === sections.LEAD_DETAILS) {
        return _saveLeadDetails(_lead, e, value);
    }
    else if (section == sections.CONTACT_DEATILS) {
        return _saveContactDetails(_lead, e, value);
    }
};

export const onGoogleDetails = (lead, detailObj, ParsedData) => {
    console.log('leadSaver onGoogleDetails ParsedData', ParsedData)
    let _lead = JSON.parse(JSON.stringify(lead));

    _lead.GoogleAddressDetail = detailObj;

    _lead.ZipCode = {
        "Id": "txtZipCode",
        "Data": ParsedData.Postal_Code
    }

    var getState = detailObj.address_components.find(function (el) {
        if (el.types[0] == "administrative_area_level_1")
            return el;
        else
            return "";
    });
    if (getState) {
        _lead.State = {
            "Id": "txtState",
            "Data": getState.short_name
        }
    } else {
        _lead.State = {
            "Id": "txtState",
            "Data": ""
        }
    }
    
    _lead.City = {
        "Id": "txtCity",
        "Data": ParsedData.Locality
    }

    _lead.Address = {
        "Id": "txtAddress",
        "Data": ParsedData.Street_Number + ' ' +  ParsedData.Route + ' ' + ParsedData.Premises
    }
      
    return _lead;
}

const _saveLeadDetails = (lead, e, value) => {
    if (e.target.name === "txtLeadName") {
        if (!lead.LeadName) {
            lead.LeadName = {
                "Id": "txtLeadName",
                "Data": ""
            }
        }
        lead.LeadName.Data = e.target.value;
    }
    else if (e.target.name === "txtLeadPhone") {
        lead.LeadPhone = {
            "Id": "txtLeadPhone",
            "Data": ""
        }
        lead.LeadPhone.Data = e.target.value;
    }
    else if (e.target.name === "txtLeadTelePhone") {
        lead.LeadTelePhone = {
            "Id": "txtLeadTelePhone",
            "Data": ""
        }
        lead.LeadTelePhone.Data = e.target.value;
    }
    else if (e.target.name === "drpPosition") {
        lead.Position = {
            "Id": "drpPosition",
            "Data": ""
        }
        lead.Position.Data = e.target.value;
    }
    else if (e.target.name === "txtSupervisor") {
        lead.Supervisor = {
            "Id": "txtSupervisor",
            "Data": ""
        }
        lead.Supervisor.Data = e.target.value;
    }
    else if (e.target.name === "txtAddress") {
        lead.Address.Data = e.target.value;
    }
    else if (e.target.name === "txtCity") {
        if (!lead.City) {
            lead.City = {
                "Id": "txtCity",
                "Data": ""
            }
        }
        lead.City.Data = e.target.value;
    }
    else if (e.target.name === "txtState") {
        if (!lead.State) {
            lead.State = {
                "Id": "txtState",
                "Data": ""
            }
        }
        lead.State.Data = e.target.value;
    }
    else if (e.target.name === "txtZipCode") {
        if (!lead.ZipCode) {
            lead.ZipCode = {
                "Id": "txtZipCode",
                "Data": ""
            }
        }
        lead.ZipCode.Data = e.target.value;
    }
    else if (e.target.name === "drpStatuses") {
        lead.Status.Data = e.target.value;
    }
    else if (e.target.name === "drpLocations") {
        lead.Location.Data = e.target.value;
    }
    else if (e.target.name === "txtLeadTitle") {
        if (!lead.LeadTitle) {
            lead.LeadTitle = {
                "Id": "txtLeadTitle",
                "Data": ""
            }
        }
        lead.LeadTitle.Data = e.target.value;
    }
    else if (e.target.name === "txtCompanyName") {
        if (!lead.CompanyName) {
            lead.CompanyName = {
                "Id": "txtCompanyName",
                "Data": ""
            }
        }
        lead.CompanyName.Data = e.target.value;
    }
    else if (e.target.name === "txtFaxNumber") {
        if (!lead.FaxNumber) {
            lead.FaxNumber = {
                "Id": "txtFaxNumber",
                "Data": ""
            }
        }
        lead.FaxNumber.Data = e.target.value;
    }
    else if (e.target.name === "txtLeadEmail") {
        if (!lead.LeadEmail) {
            lead.LeadEmail = {
                "Id": "txtLeadEmail",
                "Data": ""
            }
        }
        lead.LeadEmail.Data = e.target.value;
    }
    else if (e.target.name === "txtWebsite") {
        if (!lead.Website) {
            lead.Website = {
                "Id": "txtWebsite",
                "Data": ""
            }
        }
        lead.Website.Data = e.target.value;
    }
    else if (e.target.name === "txtReference") {
        if (!lead.Reference) {
            lead.Reference = {
                "Id": "txtReference",
                "Data": ""
            }
        }
        lead.Reference.Data = e.target.value;
    }
    else if (e.target.name === "txtNumberOfEmployees") {
        if (!lead.NumberOfEmployees) {
            lead.NumberOfEmployees = {
                "Id": "txtNumberOfEmployees",
                "Data": ""
            }
        }
        lead.NumberOfEmployees.Data = e.target.value;
    }
    else if (e.target.name === "txtNoOfPeopleRequired") {
        if (!lead.NoOfPeopleRequired) {
            lead.NoOfPeopleRequired = {
                "Id": "txtNoOfPeopleRequired",
                "Data": ""
            }
        }
        lead.NoOfPeopleRequired.Data = e.target.value;
    }
    else if (e.target.name === "txtAssignedTo") {
        if (!lead.AssignedTo) {
            lead.AssignedTo = {
                "Id": "txtAssignedTo",
                "Data": ""
            }
        }
        lead.AssignedTo.Data = value;
        // lead.AssignedTo.Data = e.target.value;
    }
    else if (e.target.name === "txtAssignedTo2") {
        if (!lead.AssignedTo2) {
            lead.AssignedTo2 = {
                "Id": "txtAssignedTo2",
                "Data": ""
            }
        }
        lead.AssignedTo2.Data = value;
        // lead.AssignedTo2.Data = e.target.value;
    }
    else if (e.target.name === "txtSalesManger") {
        if (!lead.SalesManger) {
            lead.SalesManger = {
                "Id": "txtSalesManger",
                "Data": ""
            }
        }
        lead.SalesManger.Data = value;
        // lead.SalesManger.Data = e.target.value;
    }
    else if (e.target.name === "drpLeadType") {
        if (!lead.LeadType) {
            lead.LeadType = {
                "Id": "drpLeadType",
                "Data": ""
            }
        }
        lead.LeadType.Data = e.target.value;
    }
    else if (e.target.name === "drpSourceType") {
        if (!lead.SourceType) {
            lead.SourceType = {
                "Id": "drpSourceType",
                "Data": ""
            }
        }
        lead.SourceType.Data = e.target.value;
    }
    else if (e.target.name === "drpIndustryType") {
        if (!lead.IndustryType) {
            lead.IndustryType = {
                "Id": "txtIndustryType",
                "Data": ""
            }
        }
        lead.IndustryType.Data = e.target.value;
    }
    else if (e.target.name === "txtDateSigned") {
        if (!lead.DateSigned) {
            lead.DateSigned = {
                "Id": "txtDateSigned",
                "Data": ""
            }
        }
        lead.DateSigned.Data = e.target.value;
    }
    else if (e.target.name === "txtCommission") {
        if (!lead.Commission) {
            lead.Commission = {
                "Id": "txtCommission",
                "Data": ""
            }
        }
        lead.Commission.Data = e.target.value;
    }
    else if (e.target.name === "txtOfficeServicing") {
        if (!lead.OfficeServicing) {
            lead.OfficeServicing = {
                "Id": "txtOfficeServicing",
                "Data": ""
            }
        }
        lead.OfficeServicing.Data = e.target.value;
    }
    else if (e.target.name === "txtGeneralLaborPercentage") {
        if (!lead.GeneralLaborPercentage) {
            lead.GeneralLaborPercentage = {
                "Id": "txtGeneralLaborPercentage",
                "Data": ""
            }
        }
        lead.GeneralLaborPercentage.Data = e.target.value;
    }
    else if (e.target.name === "txtOverTimePercentage") {
        if (!lead.OverTimePercentage) {
            lead.OverTimePercentage = {
                "Id": "txtOverTimePercentage",
                "Data": ""
            }
        }
        lead.OverTimePercentage.Data = e.target.value;
    }
    else if (e.target.name === "txtClericalPercentage") {
        if (!lead.ClericalPercentage) {
            lead.ClericalPercentage = {
                "Id": "txtClericalPercentage",
                "Data": ""
            }
        }
        lead.ClericalPercentage.Data = e.target.value;
    }
    else if (e.target.name === "txtRolloverPercentage") {
        if (!lead.RolloverPercentage) {
            lead.RolloverPercentage = {
                "Id": "txtRolloverPercentage",
                "Data": ""
            }
        }
        lead.RolloverPercentage.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnIsBilledSickDays") {
        if (!lead.IsBilledSickDays) {
            lead.IsBilledSickDays = {
                "Id": "rdbtnIsBilledSickDays",
                "Data": ""
            }
        }
        lead.IsBilledSickDays.Data = value;
    }
    else if (e.target.name === "txtSickDays") {
        if (!lead.SickDays) {
            lead.SickDays = {
                "Id": "txtSickDays",
                "Data": ""
            }
        }
        lead.SickDays.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnIsBilledHolidays") {
        if (!lead.IsBilledHolidays) {
            lead.IsBilledHolidays = {
                "Id": "rdbtnIsBilledHolidays",
                "Data": ""
            }
        }
        lead.IsBilledHolidays.Data = value;
    }
    else if (e.target.name === "txtHolidays") {
        if (!lead.Holidays) {
            lead.Holidays = {
                "Id": "txtHolidays",
                "Data": ""
            }
        }
        lead.Holidays.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnIsBilledVacation") {
        if (!lead.IsBilledVacation) {
            lead.IsBilledVacation = {
                "Id": "rdbtnIsBilledVacation",
                "Data": ""
            }
        }
        lead.IsBilledVacation.Data = value;
    }
    else if (e.target.name === "txtVacation") {
        if (!lead.Vacation) {
            lead.Vacation = {
                "Id": "txtVacation",
                "Data": ""
            }
        }
        lead.Vacation.Data = e.target.value;
    }
    else if (e.target.name === "txtConversionFee") {
        if (!lead.ConversionFee) {
            lead.ConversionFee = {
                "Id": "txtConversionFee",
                "Data": ""
            }
        }
        lead.ConversionFee.Data = e.target.value;
    }
    else if (e.target.name === "txtContributions") {
        if (!lead.Contributions) {
            lead.Contributions = {
                "Id": "txtContributions",
                "Data": ""
            }
        }
        lead.Contributions.Data = e.target.value;
    }
    else if (e.target.name === "txtAdditionalNote") {
        if (!lead.AdditionalNote) {
            lead.AdditionalNote = {
                "Id": "txtAdditionalNote",
                "Data": ""
            }
        }
        lead.AdditionalNote.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnIsAgency") {
        if (!lead.IsAgency) {
            lead.IsAgency = {
                "Id": "rdbtnIsAgency",
                "Data": ""
            }
        }
        lead.IsAgency.Data = value;
    }
    else if (e.target.name === "txtAgency") {
        if (!lead.Agency) {
            lead.Agency = {
                "Id": "txtAgency",
                "Data": ""
            }
        }
        lead.Agency.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnIsHaveOnSite") {
        if (!lead.IsHaveOnSite) {
            lead.IsHaveOnSite = {
                "Id": "rdbtnIsHaveOnSite",
                "Data": ""
            }
        }
        lead.IsHaveOnSite.Data = value;
    }
    else if (e.target.name === "txtMarkUpRate") {
        if (!lead.MarkUpRate) {
            lead.MarkUpRate = {
                "Id": "txtMarkUpRate",
                "Data": ""
            }
        }
        lead.MarkUpRate.Data = e.target.value;
    }
    else if (e.target.name === "txtAgency2") {
        if (!lead.Agency2) {
            lead.Agency2 = {
                "Id": "txtAgency2",
                "Data": ""
            }
        }
        lead.Agency2.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnIsHaveOnSite2") {
        if (!lead.IsHaveOnSite2) {
            lead.IsHaveOnSite2 = {
                "Id": "rdbtnIsHaveOnSite2",
                "Data": ""
            }
        }
        lead.IsHaveOnSite2.Data = value;
    }
    else if (e.target.name === "txtMarkUpRate2") {
        if (!lead.MarkUpRate2) {
            lead.MarkUpRate2 = {
                "Id": "txtMarkUpRate2",
                "Data": ""
            }
        }
        lead.MarkUpRate2.Data = e.target.value;
    }
    else if (e.target.name === "txtAgency3") {
        if (!lead.Agency3) {
            lead.Agency3 = {
                "Id": "txtAgency3",
                "Data": ""
            }
        }
        lead.Agency3.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnIsHaveOnSite3") {
        if (!lead.IsHaveOnSite3) {
            lead.IsHaveOnSite3 = {
                "Id": "rdbtnIsHaveOnSite3",
                "Data": ""
            }
        }
        lead.IsHaveOnSite3.Data = value;
    }
    else if (e.target.name === "txtMarkUpRate3") {
        if (!lead.MarkUpRate3) {
            lead.MarkUpRate3 = {
                "Id": "txtMarkUpRate3",
                "Data": ""
            }
        }
        lead.MarkUpRate3.Data = e.target.value;
    }
    
    else if (e.target.name === "drpNeedToVisit") {
        if (!lead.NeedToVisit) {
            lead.NeedToVisit = {
                "Id": "drpNeedToVisit",
                "Data": ""
            }
        }
        lead.NeedToVisit.Data = e.target.value;
    }
    else if (e.target.name === "txtMetWith") {
        if (!lead.MetWith) {
            lead.MetWith = {
                "Id": "txtMetWith",
                "Data": ""
            }
        }
        lead.MetWith.Data = e.target.value;
    }
    else if (e.target.name === "txtAnnualRevenue") {
        if (!lead.AnnualRevenue) {
            lead.AnnualRevenue = {
                "Id": "txtAnnualRevenue",
                "Data": ""
            }
        }
        lead.AnnualRevenue.Data = e.target.value;
    }
    else if (e.target.name === "drpShifts") {
        if (!lead.Shift) {
            lead.Shift = {
                "Id": "drpShifts",
                "Data": ""
            }
        }
        lead.Shift.Data = value;
    }

    return lead;
};

const _saveContactDetails = (lead, e, value) => {
    let nameParts = e.target.name.split('-');
    const name = nameParts[0];
    const id = nameParts[1];
    lead.Contacts = [].concat(lead.Contacts);
    const contact = lead.Contacts.find(p => p.id === id);
    if (name === "txtFullName") {
        if (!contact.FullName) {
            contact.FullName = {
                "Id": `txtFullName-${id}`,
                "Data": ""
            };
        }
        contact.FullName.Data = e.target.value;
    }
    else if (name === "drpPositions") {
        if (!contact.Position) {
            contact.Position = {
                "Id": `drpPositions-${id}`,
                "Data": ""
            };
        }
        contact.Position.Data = e.target.value;
    }
    else if (name === "txtContactNumber") {
        if (!contact.ContactNumber) {
            contact.ContactNumber = {
                "Id": `txtContactNumber-${id}`,
                "Data": ""
            };
        }
        contact.ContactNumber.Data = e.target.value;
    }
    else if (name === "txtEmail") {
        if (!contact.Email) {
            contact.Email = {
                "Id": `txtEmail-${id}`,
                "Data": ""
            };
        }
        contact.Email.Data = e.target.value;
    }
    else if (name === "ckbContactMaker") {
        if (!contact.ContactMaker) {
            contact.ContactMaker = {
                "Id": `ckbContactMaker-${id}`,
                "Data": ""
            };
        }
        contact.ContactMaker.Data = (value === true) ? "Y" : "N";
    }
    else if (name === "txtExtension") {
        if (!contact.Extension) {
            contact.Extension = {
                "Id": `txtExtension-${id}`,
                "Data": ""
            };
        }
        contact.Extension.Data = e.target.value;
    }
    else if (name === "txtClientInterest") {
        if (!contact.ClientInterest) {
            contact.ClientInterest = {
                "Id": `txtClientInterest-${id}`,
                "Data": ""
            };
        }
        contact.ClientInterest.Data = e.target.value;
    }

    return lead;
}

export const onValueChangedBusinessCardDetailsByPhoto = (lead, dataObject) =>{
    let _lead = JSON.parse(JSON.stringify(lead));

    _lead.LeadName.Data = dataObject.companyName;
    _lead.LeadPhone.Data = dataObject.phoneNumber;
    _lead.Supervisor.Data = dataObject.personName;
    
    return _lead;
}