import React, { useEffect } from "react";
import { IonButton, IonButtons, IonCol, IonContent, IonIcon, IonImg, IonPage, IonRow } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";

import { calendarClearOutline, callOutline, peopleOutline } from 'ionicons/icons';
import TopNavBar from "../../../components/navbars/topNavBar";
import WeekCalendar from "../../../components/calendar/weekCalendar";

// import { Geolocation, GeolocationOptions, Geoposition } from '@ionic-native/geolocation';
import { BackgroundGeolocation, BackgroundGeolocationEvents } from '@ionic-native/background-geolocation';

import { getBusinessCardDetailsByPhoto } from "../../lead/reducerSlices/leadSlice";
import { useDispatch } from "react-redux";
import { getBase64Image } from "../../../app/common/common";

import mapImg from "./../../../assets/map-icon.png";
import BootomNavBar from "../../../components/navbars/bootomNavBar";
import { saveUserLocation } from "../../locations/reducerSlices/locationSlice";
import { accountService } from "../../accounts/services/accountService";

import { Camera, CameraResultType } from '@capacitor/camera';

import { Geolocation } from '@capacitor/geolocation';

import { Plugins, PermissionResult } from '@capacitor/core';
import GMap from '../../../components/map/GMap';
import { tenantId } from "../../../app/api/client";

const GOOGLE_MAP_API_KEY = 'AIzaSyAakzSaZd7t-EBQkMQOu5U3QzhHV4yr1CY';
// load google map script
const loadGoogleMapScript = (callback) => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
        callback();
    } else {
        const googleMapScript = document.createElement("script");
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
        window.document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener("load", callback);
    }
}

function Test() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [leadList, setLeadList] = useState(false);

    //Calander Start    
    const [showDetails, setShowDetails] = useState(false);
    const [data, setData] = useState(null);

    const showDetailsHandle = (dayStr) => {
        setData(dayStr);
        setShowDetails(true);
    };
    //Calander End


    // ---------- BusinessCard ---------------
    const [base64Image, setbase64Image] = useState(undefined);

    const takePhoto = async () => {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            quality: 90,
            allowEditing: false,
        });

        console.log('photo --> ', photo)

        setbase64Image(photo.base64String);
    };

    const _getBusinessCardDetails = () => {
        if (base64Image) {
            dispatch(getBusinessCardDetailsByPhoto({
                // page: page,
                model: {
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "GETBUSINESSCARDDETAILS",
                    payload: {
                        id: '',
                        data: JSON.stringify({
                            photo: {
                                contentType: "image/jpg",
                                length: 0,
                                photoData: base64Image
                            }
                        })
                    }
                }
            }));
        }
    }

    useEffect(() => {
        _getBusinessCardDetails();
    }, [base64Image])


    // ---------- BusinessCard End ---------------





    // Location
    const getCurrentlocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Handle success
                    const { latitude, longitude } = position.coords;
                    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

                    _saveUserLocation(latitude, longitude);
                },
                (error) => {
                    // Handle error
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.error("User denied the Geolocation permission request.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.error("Location information is unavailable.");
                            break;
                        case error.TIMEOUT:
                            console.error("The request to get user location timed out.");
                            break;
                        default:
                            console.error("An unknown error occurred.");
                            break;
                    }
                }
            );
        } else {
            console.log("Geolocation API is not available");
        }

    }


    useEffect(() => {
        getCurrentlocation()
    }, []);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         // getCurrentlocation();  // help
    //     }, 60000); // 60 seconds
    //     return () => clearInterval(intervalId);
    // }, []);

    const user = accountService.getAuthenticatedUser();

    const _saveUserLocation = (latitude, longitude) => {
        console.log('_saveUserLocation Data ', latitude, longitude)
        const _locationData = { latitude, longitude };

        if (user.id) {
            dispatch(saveUserLocation({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "SAVEUSERLOCATION",
                payload: {
                    id: user.id,
                    data: JSON.stringify({
                        location: JSON.stringify(_locationData),
                    })
                }
            }));
        }
    }








    // Background GEO Location

    const handleLocationUpdate = async (position) => {
        try {
            const { latitude, longitude } = position.coords;
            // Send the location data to the API using your preferred method
            //   await axios.post('https://your-api-endpoint.com/location', { latitude, longitude });
        } catch (error) {
            console.log(error);
        }
    };




    // useEffect(() => {


    //     const backgroundGeolocation = BackgroundGeolocation;
    //     const { Geolocation } = Plugins;

    //     // Configure background location tracking settings
    //     backgroundGeolocation.configure({
    //         locationProvider: backgroundGeolocation.DISTANCE_FILTER_PROVIDER,
    //         desiredAccuracy: backgroundGeolocation.HIGH_ACCURACY,
    //         stationaryRadius: 50,
    //         distanceFilter: 50,
    //         notificationTitle: 'Background tracking',
    //         notificationText: 'ENABLED',
    //         debug: true,
    //         interval: 60000,
    //         fastestInterval: 5000,
    //         activitiesInterval: 10000,
    //         stopOnTerminate: false,
    //         startForeground: true,
    //         notificationsEnabled: true,
    //         pauseLocationUpdates: false,
    //         saveBatteryOnBackground: false,
    //         url: 'https://your-api-endpoint.com/location', // Set your API endpoint URL here
    //         httpHeaders: {
    //             'Content-Type': 'application/json',
    //         },
    //         postTemplate: {
    //             latitude: '@latitude',
    //             longitude: '@longitude',
    //         },
    //     });

    //     // Start background location tracking
    //     backgroundGeolocation.start();

    //     // Register a listener for location updates
    //     // backgroundGeolocation.onLocation((location) => {
    //     //     // handleLocationUpdate(location);
    //     //     console.log('location', location)
    //     // });

    //     BackgroundGeolocation.onLocation((location) => {
    //         console.log('[onLocation]', location);
    //     }, (error) => {
    //         console.log('[onLocation] ERROR', error);
    //     });
    //     BackgroundGeolocation.onMotionChange((location) => {
    //         console.log('[onMotionChange]', location);
    //     });


    //     return () => {
    //         // Stop background location tracking when the component unmounts
    //         backgroundGeolocation.stop();
    //     };
    // }, []);


    // backgroundGeolocation.configure({
    //     // Other configuration options...
    //     postTemplate: {
    //         latitude: '@latitude',
    //         longitude: '@longitude',
    //         userId: '1234' // Add a custom field for user ID
    //     },
    // });







    //----- MAP -----
    const [loadMap, setLoadMap] = useState(false);

    useEffect(() => {
        loadGoogleMapScript(() => {
            setLoadMap(true)
        });
    }, []);


    // list of icons
    const iconList = {
        icon1: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png',
        icon2: 'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png',
        icon3: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Ball-Right-Azure.png',
        icon4: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png'
    }

    // list of the marker object along with icon
    const markerList = [
        { lat: 59.2967322, lng: 18.0009393, icon: iconList.icon1 },
        { lat: 59.2980245, lng: 17.9971503, icon: iconList.icon2 },
        { lat: 59.2981078, lng: 17.9980875, icon: iconList.icon3 },
        { lat: 59.2987638, lng: 17.9917639, icon: iconList.icon4 }
    ]
    //----- MAP ---

    return (

        <IonPage>
            <TopNavBar
                pageTitle="Appointment Details"
            />
            <IonContent>



                <WeekCalendar showDetailsHandle={showDetailsHandle} />
                <IonRow className="ion-justify-content-center">
                    {data}
                </IonRow>

                <IonRow>
                    <IonCol size="6">
                        Camera :
                        <IonButton onClick={takePhoto}>Take Photo</IonButton>
                    </IonCol>
                    <IonCol size="6">
                        {base64Image && <IonImg src={`data:image/jpeg;base64,${base64Image}`} />}
                    </IonCol>
                </IonRow>


                {/* {error && <div>Error: {error}</div>}
                {latitude && longitude && <div>Latitude: {latitude}, Longitude: {longitude}</div>} */}

                {

                    [1].map((d) => {
                        return <IonRow key={d}>
                            <div className="appointment-card">
                                <div className="appointment-card-header-container">

                                    <div className="appointment-card-title">
                                        Nike
                                    </div>
                                    {/* <div className="appointment-card-title"> */}
                                    <IonButtons slot="end">
                                        <IonButton
                                            className="c-btn-circle appointment-card-phone-btn"
                                            fill="clear"
                                        >
                                            {/* <IonIcon src={mapImg}></IonIcon> */}
                                            <IonImg src={mapImg} alt="Map Image"></IonImg>

                                        </IonButton>
                                        <IonButton
                                            className="c-btn-circle appointment-card-phone-btn"
                                            fill="clear"
                                        >
                                            <IonIcon icon={callOutline}></IonIcon>
                                        </IonButton>
                                    </IonButtons>
                                    {/* </div> */}
                                </div>

                                <div className="appointment-card-body">
                                    <div className="appointment-card-body-container-1">
                                        <div className="c-container c-mr-3">
                                            <IonIcon className="c-circle" icon={calendarClearOutline} />
                                        </div>
                                        <div className="ml-2">
                                            <span className="appointment-card-tracking-tilte">Notes</span>
                                            <div className="appointment-card-tracking-sub-tilte">Ready to spend money with us. </div>
                                        </div>
                                    </div>
                                    <hr className="appointment-card-container-border" />
                                    <div className="appointment-card-body-container-2">
                                        <IonRow className="ion-justify-content-between ion-align-items-center">
                                            <IonCol size="auto">
                                                <div className="appointment-card-start-time-text">
                                                    <IonIcon aria-hidden="true" className="c-mr-2" icon={peopleOutline} />
                                                    Starts in 10 min
                                                </div>
                                            </IonCol>
                                            <IonCol size="auto">
                                                <IonButton
                                                    shape="round"
                                                >
                                                    Im Here
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </div>
                                </div>

                            </div>
                        </IonRow>
                    })
                }

                <IonRow>

                    <IonCol size="12">
                        {!loadMap ? <div>Loading...</div> : <GMap markerList={markerList} />}
                    </IonCol>
                </IonRow>

            </IonContent>

            <BootomNavBar />


        </IonPage>
    )
}

export default Test;