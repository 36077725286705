import React from "react";
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";

import { addOutline, alarm, flag, mail, star } from 'ionicons/icons';
import NotificationButton from "../../../components/buttons/notificationButton";
import BackButton from "../../../components/buttons/backButton";
import TopNavBar from "../../../components/navbars/topNavBar";

function Notification() {
    const history = useHistory();

    const onBackButtonClicked = (e) => {
        history.push("/lead");
    }

    const Data = [
        { title: "CRM Meeting", companyName: "Southern California", message: "Schedule Custome Meeting" },
        { title: "Product Demo", companyName: "In Win USA", message: "Robert want to meet" },
    ];

    return (
        <IonPage>
            <TopNavBar
                pageTitle="Notifications"
            />
            <IonContent>
                {
                    Data.map((d) => {
                        return <>
                            <IonItem lines="full">
                                <IonIcon icon={flag} slot="start" color="danger"></IonIcon>
                                <IonLabel>{d.companyName}</IonLabel>
                            </IonItem>
                            <IonItem lines="full">
                                <IonIcon icon={alarm} slot="start" color="warning"></IonIcon>
                                <IonLabel>{d.title}</IonLabel>
                            </IonItem>
                            <IonItem lines="full">
                                <IonIcon icon={mail} slot="start" color="primary"></IonIcon>
                                <IonLabel>{d.message}</IonLabel>
                            </IonItem>
                        </>
                    })
                }

            </IonContent>
        </IonPage>
    )
}

export default Notification;