import { IonCol, IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonModal, IonRow } from '@ionic/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

function DateTime(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    const padZero = (number) => {
        return number.toString().padStart(2, '0');
    }

    const getUTCOffset = () => {
        let date = new Date();
        let offsetMinutes = date.getTimezoneOffset();
        let hours = Math.floor(Math.abs(offsetMinutes) / 60);
        let minutes = Math.abs(offsetMinutes) % 60;
        let sign = offsetMinutes < 0 ? '+' : '-';
 
        // Format the offset as "+HHMM" or "-HHMM"
        let formattedOffset = `${sign}${padZero(hours)}${padZero(minutes)}`;

        return formattedOffset;
    }

    useEffect(() => {
        if (props.value) {
            let offset = getUTCOffset();
            const convertedTimestamp = moment(props.value)
                .utcOffset(offset)
                .format("YYYY-MM-DDTHH:mm:ssZ");

            setValue(convertedTimestamp);
        }
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    return (
        <>
            <IonRow>
                <IonCol>
                    <IonItem lines="none">
                        <IonDatetimeButton datetime={props.id}></IonDatetimeButton>
                        <IonModal keepContentsMounted={true}>
                            <IonDatetime id={props.id}
                                presentation="date-time"
                                preferWheel={true}
                                name={props.name}
                                value={value}
                                onIonChange={(e) => onChange(e)}
                                required={true}
                                data-form={props.form ? props.form : ""}
                                data-invalid={invalid}
                                data-required={props.required ? props.required : false}
                            ></IonDatetime>

                        </IonModal>
                    </IonItem>
                    {
                        invalid && <IonItem lines="none" className="c-m-0"><span className="error-label m-3">
                            <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            {props.errorMessage ? props.errorMessage : "This field is required"}
                        </span></IonItem>
                    }
                </IonCol>
            </IonRow >
        </>
    );
}

export default DateTime;