import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../app/api/client';
import { getAxiosRequestConfig, toLocalDateOnly } from '../../../app/common/common';
import { toaster } from '../../../components/controls/toasts/toaster';
import { isJSON } from '../../../app/utilities/utilityFunctions';



const initialState = {
    appointments: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid(),
        isFiltered: false,
    },
    saveAppointment: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    routeSchedules: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
    },
    calanderData: {
        selectedDate: undefined
    },
    leadRoute:{
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
    }
};

export const getAllShedules = createAsyncThunk('leads/getAllShedules', async (schedulesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, schedulesModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let schedules = undefined;
        if (data && isJSON(data)) {
            schedules = JSON.parse(data);
        }
        return {
            page: schedulesModel.page,
            schedules: schedules,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createAppointment = createAsyncThunk('leads/createAppointment', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let appointment = undefined;
        if (data && isJSON(data)) {
            appointment = JSON.parse(data);
        }
        return {
            appointment: appointment,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getRouteSchedules = createAsyncThunk('leads/getRouteSchedules', async (schedulesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, schedulesModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let schedules = undefined;
        if (data && isJSON(data)) {
            schedules = JSON.parse(data);
        }
        return {
            page: schedulesModel.page,
            schedules: schedules,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export const getLeadRoute = createAsyncThunk('leads/getLeadRoute', async (schedulesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, schedulesModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let leadRoute = undefined;
        if (data && isJSON(data)) {
            leadRoute = JSON.parse(data);
        }
        return {
            page: schedulesModel.page,
            leadRoute: leadRoute,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const scheduleSlice = createSlice({
    name: 'schedules',
    initialState,
    reducers: {
        updateIsFiltered: (state) => {
            state.appointments.isFiltered = true;
        },
        updateAppointmentsStatus: (state) => {
            state.appointments.status = fetchStatus.IDLE;
        },
        loadAppointmentsData: (state, _data) => {
            state.appointments.data = Object.assign({}, _data.payload);
        },
        updateAppointmentsData: (state, _data) => {
            state.appointments.refreshed = nanoid();
            state.appointments.data = _data.payload ? Object.assign({}, _data.payload.appointments) : _data.payload;
        },
        updateSingleAppointments: (state, _data) => {
            if (_data.payload) {
                state.appointments.data = Object.assign({}, _data.payload);
            }
        },
        updateCalanderDate:(state, action) => {
            if (action.payload) {
                const { date } = action.payload
                state.calanderData.selectedDate = date;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllShedules.pending, (state, action) => {
            state.appointments.status = fetchStatus.LOADING;
        }).addCase(getAllShedules.fulfilled, (state, action) => {
            state.appointments.data = action.payload.schedules;
            let calanderData = action.payload.schedules.map(function (d) {
                // web Application START
                // let cd = { ...d.calendarData, "appointmentId": d.id, "leadName":d.leadName }
                // return cd
                // web Application END
                let cd = { ...d, "_appointmentLocalDate": toLocalDateOnly(d.calendarData.StartTime) }
                return cd
            });
            state.appointments.data = calanderData;
            state.appointments.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAllShedules.rejected, (state, action) => {
            state.appointments.status = fetchStatus.FAILED;
            state.appointments.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createAppointment.pending, (state, action) => {
            state.saveAppointment.status = fetchStatus.LOADING;
        }).addCase(createAppointment.fulfilled, (state, action) => {
            state.saveAppointment.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createAppointment.rejected, (state, action) => {
            state.saveAppointment.status = fetchStatus.FAILED;
            state.saveAppointment.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getRouteSchedules.pending, (state, action) => {
            state.routeSchedules.status = fetchStatus.LOADING;
        }).addCase(getRouteSchedules.fulfilled, (state, action) => {
            state.routeSchedules.data = action.payload.schedules;
            state.routeSchedules.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getRouteSchedules.rejected, (state, action) => {
            state.routeSchedules.status = fetchStatus.FAILED;
            state.routeSchedules.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLeadRoute.pending, (state, action) => {
            state.leadRoute.status = fetchStatus.LOADING;
        }).addCase(getLeadRoute.fulfilled, (state, action) => {
            state.leadRoute.data = action.payload.leadRoute.items;
            state.leadRoute.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getLeadRoute.rejected, (state, action) => {
            state.leadRoute.status = fetchStatus.FAILED;
            state.leadRoute.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { updateAppointmentsData, updateIsFiltered , updateCalanderDate} = scheduleSlice.actions;

export default scheduleSlice.reducer

export const selectAllSchedules = (state) => {
    return state.schedules.appointments ? state.schedules.appointments.data : undefined;
}
export const selectStatus = state => state.schedules.appointments.status;

export const selectSaveAppointmentStatus = state => state.schedules.creationAppointment.status;

export const selectRouteSchedules = (state) => {
    return state.schedules.routeSchedules ? state.schedules.routeSchedules.data : undefined;
}

export const selectRouteSchedulesStatus = state => state.schedules.routeSchedules.status;

export const selectCalanderSelectedDate = state => state.schedules.calanderData.selectedDate;

export const selectLeadRoute = (state) => {
    return state.schedules.leadRoute ? state.schedules.leadRoute.data : undefined;
}
export const selectLeadRouteStatus = state => state.schedules.leadRoute.status;