import { IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React from 'react';

function BackButton(props) {
    return (
        <IonButton
            fill="clear"
            onClick={(e) => props.onBackButtonClicked && props.onBackButtonClicked(e)}>
            <IonIcon icon={arrowBack}></IonIcon>
        </IonButton>
    );
}

export default BackButton;