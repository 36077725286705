import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';

function SaveButton(props) {
    return (
        <IonButton
            className="c-btn-save"
            fill="clear"
            expand="block"
            onClick={(e) => props.onSaveButtonClicked && props.onSaveButtonClicked(e)}>
            Save
        </IonButton>
    );
}

export default SaveButton;