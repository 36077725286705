import React, { useEffect } from "react";
import { IonAvatar, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSearchbar, IonTitle, IonToast, IonToolbar } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";

import { addOutline, callOutline, search } from 'ionicons/icons';
import TopNavBar from "../../../components/navbars/topNavBar";
import { createNewLead, getAllLeads, getLeadsSearch, selectAllLeads, selectLeadById, selectLeadsSearch, selectLeadsSearchStatus, selectStatus, selectTotalItems, updateRecentClickedLead, updateSelectedLeadsSearch, updateSingleData, updateSingleLeadSearchData } from "../reducerSlices/leadSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatus, tenantId } from "../../../app/api/client";
import { accountService } from "../../accounts/services/accountService";
import BootomNavBar from "../../../components/navbars/bootomNavBar";
import GridLoader from "../../../components/loaders/gridLoader";
import axios from "axios";
import { Operation } from "../../../app/common/constants";
import { toLocalDate } from "../../../app/common/common";
import ShortText from "../../../components/controls/textboxes/shortText";
import { selectLiveLocation } from "../../locations/reducerSlices/locationSlice";

function LeadSearch() {

    const history = useHistory();
    const dispatch = useDispatch();


    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const leadsSearchStatus = useSelector(selectLeadsSearchStatus);
    const leadsSearch = useSelector(selectLeadsSearch);

    const [searchQuery, setSearchQuery] = useState("");

    const liveLocation = useSelector(selectLiveLocation);
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState(false);

    // Location
    const getCurrentlocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Handle success
                    const { latitude, longitude } = position.coords;
                    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
                    setLatitude(latitude);
                    setLongitude(longitude);
                },
                (error) => {
                    // Handle error
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.error("User denied the Geolocation permission request.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.error("Location information is unavailable.");
                            break;
                        case error.TIMEOUT:
                            console.error("The request to get user location timed out.");
                            break;
                        default:
                            console.error("An unknown error occurred.");
                            break;
                    }
                }
            );
        } else {
            console.log("Geolocation API is not available");
        }
    }

    // useEffect(() => {
    //     getCurrentlocation()
    // }, []);

    const _loadLeadSearch = async (whereClause) => {
        await dispatch(getLeadsSearch({
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLEADSSEARCH",
                payload: {
                    data: JSON.stringify({
                        where: whereClause,
                    })
                }
            }
        }));
    }

    useEffect(() => {
        if (liveLocation) {
            setLatitude(liveLocation.latitude);
            setLongitude(liveLocation.longitude);
        }else{
            setLatitude(34.037111251795864); // Need Remove in Production
            setLongitude(-118.31839848667072); // Need Remove in Production
        }
    }, [liveLocation])

    const onSearch = () => {
        if (latitude && longitude && searchQuery) {
            let location = latitude + ',' + longitude;
            let whereClause = { "location": location, "keyword": "", "radius": "5000" };
            whereClause.keyword = searchQuery;
            _loadLeadSearch(whereClause);
        } else {
            if (searchQuery === "") {
                setIsOpenToast(true);
                setToastMessage("Please enter a search term.");
            } else {
                if (!latitude || !longitude) {
                    setIsOpenToast(true);
                    setToastMessage("1. Make sure location services are turned on.<br>2. Allow location permissions.<br>3. If the problem is not resolved,<br>&ensp;&ensp;Please log out and log in again.");
                }
            }
        }
    }

    const onValueChanged = (_leadObj, dataObject) => {
        let _lead = JSON.parse(JSON.stringify(_leadObj));
        _lead.LeadName.Data = dataObject.LeadName;
        return _lead;
    }

    const addLead = (e, searchData) => {
        console.log(searchData);

        dispatch(createNewLead());
        dispatch(updateRecentClickedLead(0));
        dispatch(updateSingleLeadSearchData(searchData));

        let emptyLeadObj = {
            "LeadName": {
                "Id": "txtLeadName",
                "Data": ""
            },
            "LeadPhone": {
                "Id": "txtLeadPhone",
                "Data": ""
            },
            "LeadTelePhone": {
                "Id": "txtLeadTelePhone",
                "Data": ""
            },
            "Position": {
                "Id": "drpPosition",
                "Data": ""
            },
            "Supervisor": {
                "Id": "txtSupervisor",
                "Data": ""
            },
            "Address": {
                "Id": "txtAddress",
                "Data": ""
            },

            "City": {
                "Id": "txtCity",
                "Data": ""
            },
            "State": {
                "Id": "txtState",
                "Data": ""
            },
            "ZipCode": {
                "Id": "txtZipCode",
                "Data": ""
            },
            "Location": {
                "Id": "drpLocations",
                "Data": ""
            },
            "LeadType": {
                "Id": "drpLeadType",
                "Data": "Create"
            },
            "Status": {
                "Id": "drpStatuses",
                "Data": "New"
            },
            "AssignedTo": {
                "Id": "txtAssignedTo",
                "Data": ""
            },
            "ApplicantId": "",
            "LeadDate": "",
            "Contacts": [

            ],
            "GoogleAddressDetail": []
        };

        let _leadObj = Object.assign({}, emptyLeadObj);
        console.log('_leadObj', _leadObj)
        let _newLeadObj = onValueChanged(_leadObj, searchData);

        dispatch(updateSingleData({
            lead: _newLeadObj,
            operation: Operation.UPDATED
        }));
        history.push("/leadDetails");

    }

    return (
        <IonPage>
            {leadsSearchStatus === fetchStatus.LOADING ? <div className="process-loader">
                <GridLoader />
            </div> : <></>}
            <TopNavBar
                pageTitle={`All Leads`} />
            <IonContent>
                <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top ion-margin-start ion-margin-end">
                    <IonCol size="12">
                        <IonLabel className="c-heading">Search Prospects</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-between ion-margin">
                    <IonCol size="12">
                        {/* <IonSearchbar className="c-ion-searchbar" value={searchQuery} onIonChange={e => setSearchQuery(e.detail.value)}>
                            <IonButton
                                fill="clear"
                                onClick={onSearch}>
                                <IonIcon icon={search} />
                            </IonButton>
                        </IonSearchbar> */}
                        <IonRow>
                            <IonInput
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                className="form-control"
                                onIonChange={e => setSearchQuery(e.detail.value)}
                            >
                            </IonInput>
                            <IonButton
                                color="dark"
                                fill="clear"
                                onClick={onSearch}>
                                <IonIcon icon={search} />
                            </IonButton>
                        </IonRow>
                    </IonCol>
                </IonRow>

                {
                    leadsSearch && leadsSearch.map((d, index) => {
                        return <IonCard className={`c-lead-card ${d.typeRecords === 'exist' ? 'lead-search-exist' : 'lead-search-new'}`} key={index}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <IonCardTitle>{d.LeadName}  </IonCardTitle>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    {d.AssignedTo && <>
                                        <IonCol size="5">
                                            Assigned To
                                        </IonCol>
                                        <IonCol size="7">
                                            <span className="lead-search-text">{d.AssignedTo}</span>
                                        </IonCol>
                                    </>
                                    }
                                    {d.LastUpdated && <>
                                        <IonCol size="5">
                                            Last Updated
                                        </IonCol>
                                        <IonCol size="7">
                                            <span className="lead-search-text">{toLocalDate(d.LastUpdated)}</span>
                                        </IonCol>
                                    </>
                                    }
                                    {d.typeRecords === 'new'
                                        &&
                                        <IonCol className="ion-text-end">
                                            <IonButton
                                                className="c-add-btn"
                                                fill="clear"
                                                onClick={(e) => addLead(e, d)}>
                                                Add as lead
                                            </IonButton>
                                        </IonCol>
                                    }
                                </IonRow >
                            </IonCardContent>
                        </IonCard>
                    })
                }

                <IonToast
                    position="top"
                    header="Something went wrong"
                    color="danger"
                    isOpen={isOpenToast}
                    message={toastMessage}
                    onDidDismiss={() => setIsOpenToast(false)}
                    duration={4000}
                ></IonToast>

            </IonContent>
            <BootomNavBar active="leadSearch" />
        </IonPage>
    )
}

export default LeadSearch;