import { USER_KEY } from "./constants";

const getToken = () => {
    let user;
    if (localStorage.getItem(USER_KEY)) {
        user = JSON.parse(localStorage.getItem(USER_KEY));
    }
    return user ? `Bearer fake-jwt-token ${user.jwtToken}` : "";
}

export const getAxiosRequestConfig = () => {
    return {
        headers: {
            'Authorization': getToken()
        }
    }
}

// export const atob = (inputString) => {
//     return Buffer.from(inputString, 'binary').toString('base64');
// };

// export const btoa = (inputString) => {
//     return Buffer.from(inputString, 'base64').toString('binary');
// };

export const getBase64Image = file => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
        };
        console.log(fileInfo);
    });
};


export const toLocalDate = (utcDateString) => {
    try {
        let date = new Date(utcDateString);
        return date.toLocaleString();
    }
    catch
    {
        return "";
    }
};


export const toLocalDateOnly = (utcDateString) => {
    try {
        let date = new Date(utcDateString);
        return date.toLocaleDateString();
    }
    catch
    {
        return "";
    }
};

export const toLocalTimeOnly = (utcDateString) => {
    try {
        let date = new Date(utcDateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    catch
    {
        return "";
    }
};

export const _getDateText = (date) => {
    if (date) {
        let dateParts = date.split('-');
        return dateParts && dateParts.length === 3 ? `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}` : "";
    }
    return "";
}

export const _getUtcTimestamp = (timestamp) => {
    try {
        var date = new Date(timestamp);
        var utcTimestamp = date.getTime();
        return utcTimestamp;
    } catch {
        return "";
    }
}

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};

export const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    name = getInitials(name)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context.fillStyle = "#ffffff"
    context.fillRect(0, 0, size, size)

    context.fillStyle = `${color}50`
    context.fillRect(0, 0, size, size)

    context.fillStyle = color;
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `${size / 2}px Roboto`
    context.fillText(name, (size / 2), (size / 2))

    return canvas.toDataURL()
};

export const createImageFromInitialsBold = (size, name, color) => {
    if (name == null) return;
    name = getInitials(name)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context.fillStyle = "#ffffff"
    context.fillRect(0, 0, size, size)

    context.fillStyle = `${color}50`
    context.fillRect(0, 0, size, size)

    context.fillStyle = color;
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `bold ${Math.round(size / 1.3)}px Roboto`
    const textX = size / 2;
    const textY = size / 2 + size / 12; 
    context.fillText(name, textX, textY);

    return canvas.toDataURL()
};