import React from 'react';
import { IonFooter, IonIcon, IonLabel, IonRow, IonTabButton } from '@ionic/react';
import { analyticsOutline, calendarOutline, home, search } from 'ionicons/icons';
import { useHistory } from 'react-router';

function BootomNavBar(props) {
    const history = useHistory();

    const onClickTab = (tabName) => {
        history.push(tabName);
    }

    return (
        <footer className="c-bootom-navbar">
            <IonFooter className="ion-no-border">
                <IonRow className="ion-justify-content-center">
                    <IonTabButton tab="tab1" onClick={() => onClickTab('appointment')} className={props.active === 'appointment' ? 'c-bootom-ion-tab-button-active' : ''}>
                        <IonIcon aria-hidden="true" icon={calendarOutline} className={props.active === 'appointment' ? 'c-bootom-navbar-icon-active' : ''} />
                        <IonLabel className={props.active === 'appointment' ? 'c-bootom-navbar-text-active' : 'c-bootom-navbar-text'} >Appointments</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="tab1" onClick={() => onClickTab('routeList')} className={props.active === 'routeList' ? 'c-bootom-ion-tab-button-active' : ''}>
                        <IonIcon aria-hidden="true" icon={analyticsOutline} className={props.active === 'routeList' ? 'c-bootom-navbar-icon-active' : ''} />
                        <IonLabel className={props.active === 'routeList' ? 'c-bootom-navbar-text-active' : 'c-bootom-navbar-text'} >Route List</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="tab2" onClick={() => onClickTab('lead')} className={props.active === 'lead' ? 'c-bootom-ion-tab-button-active' : ''}>
                        <IonIcon aria-hidden="true" icon={home} className={props.active === 'lead' ? 'c-bootom-navbar-icon-active' : ''} />
                        <IonLabel className={props.active === 'lead' ? 'c-bootom-navbar-text-active' : 'c-bootom-navbar-text'} >Pipeline</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="tab3" onClick={() => onClickTab('leadSearch')} className={props.active === 'leadSearch' ? 'c-bootom-ion-tab-button-active' : ''}>
                        <IonIcon aria-hidden="true" icon={search} className="c-bootom-navbar-icon" />
                        <IonLabel className={props.active === 'leadSearch' ? 'c-bootom-navbar-text-active' : 'c-bootom-navbar-text'}>Search</IonLabel>
                    </IonTabButton>
                </IonRow>
            </IonFooter>
        </footer>
    );
}

export default BootomNavBar;