import React, { useEffect, useState } from 'react';
import useEmailValidator from '../../hooks/useEmailValidator';
import { IonInput, IonLabel } from '@ionic/react';

// import './css/input.css';

function EmailText(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    let emailValid = useEmailValidator(value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setIsEmailValid(emailValid);
    }, [props.value, emailValid]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    return (
        <>
            {/* <input
                type="email"
                id={props.id}
                name={props.name}
                value={value}//props.value ? props.value : ""}
                className={`${props.EmailTextClasses ? props.EmailTextClasses : ""} ${invalid && "invalid-input"}`}
                placeholder={`${props.placeholder ? props.placeholder : ""}`}
                onChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
            /> */}
            <IonInput
                type="email"
                id={props.id}
                name={props.name}
                value={value}
                className={`${props.EmailTextClasses ? props.EmailTextClasses : ""} ${invalid && "invalid-input"}`}
                placeholder={`${props.placeholder ? props.placeholder : ""}`}
                onIonInput={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
            ></IonInput>
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
            {
                (value && !isEmailValid) && <span className="error-label">
                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    Please enter valid email address
                </span>
            }
            {/* {
                invalid && <IonLabel position="stacked" color="danger">{props.errorMessage ? props.errorMessage : "This field is required"}</IonLabel>
            }
            {
                (value && !isEmailValid)  && <IonLabel position="stacked" color="danger">Please enter valid email address</IonLabel>
            } */}
        </>
    );
}

export default EmailText;