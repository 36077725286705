import React from "react";

import {
    IonAvatar,
    IonContent,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonText,
    IonToggle,
} from '@ionic/react';

import {
    archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp,
    paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, logOutOutline, logOutSharp
} from 'ionicons/icons';
import './menu.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectLoggedInUser, selectLogoutStatus } from '../../pages/accounts/reducerSlices/accountsSlice';
import { accountService } from '../../pages/accounts/services/accountService';
import { fetchStatus, tenantId } from '../../app/api/client';
import { useEffect, useState } from 'react';
import { USER_KEY } from '../../app/common/constants';
import { saveUserLocation, selectLiveLocation } from '../../pages/locations/reducerSlices/locationSlice';
import { BackgroundLocationService, guessLocation } from "../../app/services/BackgroundLocationService";
// import LocationTracker from "../location/LocationTracker";

const Menu = (props) => {

    const dispatch = useDispatch();
    const user = useSelector(selectLoggedInUser);
    const logoutStatus = useSelector(selectLogoutStatus);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [trackMe, setTrackMe] = useState(true);

    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const liveLocation = useSelector(selectLiveLocation);
    const [isLoginLocationSave, setIsLoginLocationSave] = useState(false);

    useEffect(() => {
        setIsAuthenticated(user && user.id ? true : false);
    }, [user]);

    useEffect(() => {
        setTrackMe(true);
    }, []);

    const onLogoutClicked = async (e) => {
        if (latitude && longitude) {
            if (!isLoginLocationSave) {
                await _saveUserLocation(latitude, longitude, "logout");
            }
        }
        setIsLoginLocationSave(false);
        setTrackMe(false);
        if (accountService.isAuthenticated()) {
            try {
                localStorage.removeItem(USER_KEY);
                //history.replace('/login');
                window.location.reload();
            }
            catch (err) {
                console.error('Failed to log out: ', err)
            }
        }
    };

    const userDeatils = accountService.getAuthenticatedUser();

    const _saveUserLocation = (latitude, longitude, trackingType = "throughout") => {
        console.log('_saveUserLocation Data ', latitude, longitude)
        const _locationData = { latitude, longitude };
        if (latitude != '' && longitude != '') {
            if (userDeatils.id) {
                dispatch(saveUserLocation({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "SAVEUSERLOCATION",
                    payload: {
                        id: userDeatils.id,
                        data: JSON.stringify({
                            location: JSON.stringify(_locationData),
                            trackingType: trackingType
                        })
                    }
                }));
            }
        }
    }



    const handleToggleTrackMe = (event) => {
        setTrackMe(event.target.checked);
    }


    const handleLocation = (location) => {
        console.log("Last location:", location);
        if (location && location.latitude && location.longitude) {
            _saveUserLocation(location.latitude, location.longitude)
        }
    };

    useEffect(() => {
        if (trackMe) {
            //alert("first Time location");
            guessLocation(handleLocation, 5000);
            setTimeout(() => {
                guessLocation(handleLocation, 5000);
            }, 15000);
        }
    }, [trackMe]);

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        //if (trackMe) {
        //alert("repeate Time location");
        const intervalId = setInterval(() => {
            setCounter(counter + 1);
            console.log('counter', counter)
            guessLocation(handleLocation, 5000);
        }, 180000); // location tracking timer in milliseconds

        return () => clearInterval(intervalId);
        //}
    }, [counter]);


    useEffect(() => {
        if (liveLocation) {
            setLatitude(liveLocation.latitude);
            setLongitude(liveLocation.longitude);
        }
    }, [liveLocation]);

    //Login Track
    useEffect(() => {
        if (latitude && longitude) {
            if (!isLoginLocationSave) {
                _saveUserLocation(latitude, longitude, "login");
                setIsLoginLocationSave(true);
            }
        }
    }, [latitude, longitude]);

    return (
        <>{isAuthenticated ?
            <IonMenu contentId="main" type="overlay" side="end" >
                <IonContent >
                    <IonList id="inbox-list">
                        <IonListHeader>
                            <div className="person-detail-container">
                                {/* <IonImg src={(user ? user.photoPath : nophoto) || nophoto} className="person-photo" /> */}
                                <IonText>{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}</IonText>
                                <IonNote>{user ? user.email : "na@na.com"}</IonNote>
                            </div>

                        </IonListHeader>
                    </IonList>

                    <IonList>
                        <IonItem>
                            <IonToggle checked={trackMe} onIonChange={handleToggleTrackMe} />Track Me
                        </IonItem>
                    </IonList>



                    <IonList>
                        <IonMenuToggle autoHide={false}>
                            <IonItem
                                lines="none" detail={false}
                                onClick={(e) => onLogoutClicked(e)}>
                                <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
                                <IonLabel>Logout</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                    </IonList>

                    {/* <LocationTracker trackMe={trackMe}/> */}

                    {
                        trackMe &&
                        <BackgroundLocationService />
                    }

                    {/* <IonList>
                        <IonItem>
                            counter : {counter}
                        </IonItem>
                    </IonList> */}


                </IonContent >

            </IonMenu > : <></>}
        </>
    );
};

export default Menu;
